import HeaderSolutionsEx from "../../components/Solutions/HeaderSolutionsEx";
import BannerSolutions from "../../components/Solutions/BannerSolutions";


import IMG_1 from "../../assets/2022/06/Groupe-DP.png"; 
import IMG_2 from "../../assets/2022/06/piloter.png"; 
import IMG_3 from "../../assets/2022/07/cycle_donnees.png"; 
import IMG_4 from "../../assets/2022/07/Groupe-3209.svg"; 

export default function Patrimoine () {

    const content_1 = () => {
        return (
            <>
                <h3 style={{fontWeight: 800, color: "#1B7AFA", fontSize: 20}}>
                    Créer et développer ses actifs Data

                </h3>
                <p>Nous partons du principe qu’une marque doit capter et valoriser toutes ses données, et qu’elle doit saisir les opportunités d’en être propriétaire.</p>
                <h3 style={{fontWeight: 800, color: "#1B7AFA", fontSize: 20}}>
                    Concentrer sa connaissance client
                </h3>
                <p>
                    Il est possible de surmonter l’éparpillement des données dans les multiples canaux de communication, points de contact.
                </p>
                <p>
                    <b>Swatix</b> propose une solution intégrée pour concentrer toutes ces données et les exploiter,  en respect des réglementations.
                </p>
            </>
        )
    }

    const content_2 = () => {
        return (
            <>
                <h3 style={{fontWeight: 800, color: "#1B7AFA", fontSize: 20}}>
                    S’appuyer sur une approche globale de gouvernance des données client


                </h3>
                <p>Swatix a été conçu pour répondre à une gouvernance de données ambitieuse et holistique.

                </p>

                <ul>
                    <li>
                        Créer des socles de décisions riches et performants
                    </li>
                    <li>
                        Alimenter des historiques de données qui seront le socle des approches futures
                    </li>
                    <li>
                        Ouvrir les opportunités de croisement et d’enrichissement.
                    </li>
                </ul>
            </>
        )
    }

    const content_3 = () => {
        return (
            <>
                <h3 style={{fontWeight: 800, color: "#1B7AFA", fontSize: 20}}>
                    Pérennité & Souveraineté



                </h3>
                <p><b>Swatix</b> a fait le choix de déployer son infrastructure technique exclusivement sur le territoire français et empêche tout transfert de données hors de l’Union Européenne.

                </p>
                <h3 style={{fontWeight: 800, color: "#1B7AFA", fontSize: 20}}>
                    Data Ownership

                </h3>

                <p>Pour garantir l’indépendance de ses clients, <b>Swatix</b> duplique en continue la totalité des données (Brutes & Structurées) pour lesquelles elle a mandat, sur les serveurs de ses clients qui thésaurisent un nouvel actif stratégique.

                </p>
            </>
        )
    }

    return (
<div>
    <HeaderSolutionsEx title={"PATRIMOINE DATA"} subtitle={"Une stratégie first party data"} content1={content_1()} image1={IMG_1} />
    <HeaderSolutionsEx title={""} subtitle={""} content1={content_2()} image1={IMG_2} reverse={true}/>
    <BannerSolutions content={"Swatix rend possible et standardise les opérations deConstitution du Patrimoine Data de l’entreprise\n" +
        "sans compromis avec le respect des réglementations européennes"}/>

    <div style={{height:400, backgroundColor: "#f1f4f9", padding: 100}} >
        <img src={IMG_3} style={{maxHeight: "100%", maxWidth: "100%", display: "block", margin: "auto"}}/>
    </div>
    <HeaderSolutionsEx  title={""} subtitle={""} content1={content_3()} image1={IMG_4} reverse={false}/>
</div>

    )
}