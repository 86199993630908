export default function HeaderSolutionsEx({
                                              title,
                                              subtitle,
                                              content1,
                                              image1,
                                              content2 = <></>,
                                              reverse = false,
                                              interactive = false,
                                              interactive_content = <></>,
    avoidTopMargin = false
                                          }) {
    if (reverse)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 150,
                    padding: 40,
                    marginTop: avoidTopMargin? 0 : 50 ,
                }}
            >
                {interactive ?
                    interactive_content
                    :
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={image1}
                            alt={"image"}
                            style={{
                                maxWidth: 400,
                                height: "auto",
                                maxHeight: 400,
                                marginTop: 50,
                                objectFit: "cover",
                            }}
                        />
                    </div>
                }
                <div>
                    <div style={{ flex: 1, maxWidth: 550, lineHeight: "2em" }}>
                        <h2
                            style={{
                                fontWeight: 500,
                                color: "#1B7AFA",
                                textTransform: "uppercase",
                                fontSize: 18,
                            }}
                        >
                            <b>{title}</b>
                        </h2>
                        <h1
                            style={{
                                fontWeight: 800,
                                fontSize: "35px",
                                lineHeight: "1.2em",
                                overflow: "visible",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <b>{subtitle}</b>
                        </h1>
                        <p>{content1}</p>

                        {content2}
                    </div>
                </div>
            </div>
        );
    else
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 150,
                    padding: 40,
                    marginTop: avoidTopMargin? 0 : 50,
                }}
            >
                <div>
                    <div style={{ flex: 1, maxWidth: 550, lineHeight: "2em" }}>
                        <h2
                            style={{
                                fontWeight: 500,
                                color: "#1B7AFA",
                                textTransform: "uppercase",
                                fontSize: 18,
                            }}
                        >
                            <b>{title}</b>
                        </h2>
                        <h1
                            style={{
                                fontWeight: 800,
                                fontSize: "35px",
                                lineHeight: "1.2em",
                                overflow: "visible",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <b>{subtitle}</b>
                        </h1>
                        <p>{content1}</p>
                    </div>
                </div>

                {interactive ?
                    interactive_content
                    :
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={image1}
                            alt={"image"}
                            style={{
                                maxWidth: 400,
                                height: "auto",
                                maxHeight: 400,
                                marginTop: 50,
                                objectFit: "cover",
                            }}
                        />
                    </div>
                }
            </div>
        );
}