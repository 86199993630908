import HeaderSolutionsEx from "../../components/Solutions/HeaderSolutionsEx";
import BannerSolutions from "../../components/Solutions/BannerSolutions";
import SquareCardCustom from "../../components/SquareCardCustom";
import GridSquareCardCustom from "../../components/GridSquareCardCustom";


import IMG_1 from "../../assets/2022/06/Groupe-1681.svg";
import IMG_2 from "../../assets/2022/06/Groupe-1672.svg";
import IMG_3 from "../../assets/2022/06/Groupe-1677.svg";
import IMG_4 from "../../assets/2022/06/Groupe-1671.svg"; 
import IMG_5 from "../../assets/2022/06/Groupe-2748.png"; 


const list_1 = ["Analyse des médias", "Interactions du contenu", "Transitions", "Fonctionnalités d’optimisation de la conversion", "Profils des visiteurs", "Référencement SEO amélioré", "E-commerce", "Rapports personnalisés", "Suivi des campagnes", "Consolidation depuis plusieurs sites Web"]
const list_2 = ["Le parcours dans le temps des utilisateurs individuels", "Comprendre comment se fait l’engagement sur votre site avant la conversion", "Suivez vos efforts de rétention pour que vos visiteurs continuent à avoir envie de revenir", "Savoir « où, quand, comment » pour offrir une meilleure expérience utilisateur et augmenter les conversions", "Suivre des utilisateurs particuliers avec des ID Utilisateur"]
const list_3 = ["Cartes de chaleur", "Enregistrements de session", "Entonnoirs", "Tests A/B", "Analyse de formulaires", "Objectifs"]
const list_4 = ["L’intégration avec Google, Bing et Yahoo Search", "L’élimination de « Mot-clé indéfini » dans les rapports de référence", "Positions dans le classement des mots-clés", "Statistiques de crawling"]

export default function Donnee () {





    const content_1 = (list: string[]) => {
        return (
            <ul>
                {list.map((el) => {
                    return <li>{el}</li>
                })}
            </ul>
        )
    }

    const row_1 = [
        {
            content: content_1(list_1),
            title: "ANALYTICS",
            logo: IMG_1
        },
        {
            content: content_1(list_2),
            title: "PROFILS DES VISITEURS",
            logo: IMG_2
        },

    ]

    const row_2 = [
        {
            content: content_1(list_3),
            title: "OPTIMISATION DE LA CONVERSION",
            logo: IMG_3
        },
        {
            content: content_1(list_4),
            title: "AMÉLIORATION DU RÉFÉRENCEMENT",
            logo: IMG_4
        },

    ]

    const content_1_header = () => {
        return (<><b>Swatix</b> repose sur un socle technologique qui garantit à chacun de ses clients :
            <br />
            {content_2_header()}
        </>)
    }

    const content_2_header = () => {
        return (
            <>
                <ul>
                    <li>
                        La conformité RGPD de son écosystème Web Marketing
                    </li>

                    <li>
                        La pleine propriété des données collectées
                    </li>
                </ul>
                <p>
                    Avec <b>Swatix</b>, toutes les données collectées issues des relations et interactions entre un site Web et ses internautes restent la PLEINE et ENTIÈRE propriété de l’annonceur.
                </p>
            </>
        )
    }


    return (
        <div>
        <HeaderSolutionsEx title={"ÉCONOMIE DE LA DONNÉE"} subtitle={"Exhaustivité & Data Ownership"} image1={IMG_5} content1={content_1_header()} content2={content_2_header()}/>

            <BannerSolutions content={"Avec Swatix, l’exactitude des données collectées est garantie à 100% : aucun échantillonnage des données n’est utilisé pour aucune des formules de collecte de la donnée analytique."}/>
            <div style={{display: "flex", flexDirection:"column", paddingRight: 150, paddingLeft: 150, paddingTop: 50,gap: 30, paddingBottom: 50, backgroundColor: "#f1f4f9" }}>
            <GridSquareCardCustom listCard={row_1} listMode={true}/>
                <GridSquareCardCustom listCard={row_2} listMode={true}/>
            </div>
        </div>
    )
}