import HeaderSolutionsEx from "../../components/Solutions/HeaderSolutionsEx";
import InformationHeader from "../../components/InformationHeader";
import BannerSolutions from "../../components/Solutions/BannerSolutions";


import IMG_1 from "../../assets/2022/06/Groupe-rgpd.png"; 
import IMG_2 from "../../assets/2022/06/GDPR.png";  
import IMG_3 from "../../assets/2022/06/tagmanager.png";  

export default function Rgpd() {

    const content_1 = () => {
        return (<>

                <p><b>Swatix</b> a été conçue pour proposer une technologie respectueuse de la vie privée.</p>
                <p>Toutes les données collectées sont stockées et sécurisées dans votre espace dédié, aucune autre
                    entité tierce ne peut accéder à vos informations.</p>
                <p><b>Swatix</b> intègre et est conforme à toutes les recommandations et obligations édictées par les
                    DPA européennes.</p>
            </>)
    }

    const content_2 = () => (<>
            <ul>
                <li>
                    Cookies de première partie par défaut
                </li>
                <li>
                    Anonymisation des données
                </li>
                <li>
                    Possibilité pour l’utilisateur de refuser tout suivi
                </li>
                <li>
                    Possibilité pour l’utilisateur de consulter les données recueillies
                </li>
                <li>
                    Possibilité de supprimer les données des visiteurs sur demande
                </li>
                <li>
                    Données strictement utilisées aux fins établies
                </li>
                <li>
                    Anonymisation de l’adresse IP
                </li>
                <li>
                    Désactivation possible du journal des visiteurs et des profils
                </li>
                <li>
                    Stockage des données circonscrites au territoire Français
                </li>
            </ul>
        </>)
    const content_3 = () => (
        <p>
            <b>Swatix</b> intègre entre autres un gestionnaire de balises rendant possible l’utilisation du système <b>« Exportation de conversion publicitaire »</b> qui permet de s’affranchir de l’usage de toute balise pour ses opérations de SEA (Google Ads, Microsoft Ads …)
        </p>
    )

    return (<div>
            <HeaderSolutionsEx title={"RÈGLEMENTATION"} subtitle={"RGPD & Normes Européennes"} content1={content_1()}
                               image1={IMG_1}/>
            <BannerSolutions
                content={"L’utilisation de Swatix et de son socle technologique permet de rendre conforme au Droit Européen, la collecte et l’exploitation de toutes les données de votre écosystème Web numérique."}/>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center",flexDirection: "column", gap: 50,backgroundColor: "#f1f4f9", padding: 50, flex: 1}}>
                <div style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "center",
                    flex: 1,
                    // margin: "auto",
                    // marginTop: 0,
                    boxShadow: "0px 3px 18px -6px rgba(0,0,0,0.3)",
                    backgroundColor: "white",
                    borderRadius: 20,
                }}>
                    <HeaderSolutionsEx avoidTopMargin={true} title={"Une configuration RGPD intégrée"} subtitle={""} content1={content_2()}
                                       image1={IMG_2}/>

                </div>
                <div style={{
                    width: "70%",
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    // margin: "auto",
                    // marginTop: 0,
                    boxShadow: "0px 3px 18px -6px rgba(0,0,0,0.3)",
                    backgroundColor: "white",
                    borderRadius: 20,
                }}>
                    <HeaderSolutionsEx avoidTopMargin={true} title={"Gestionnaire de balises\n"} subtitle={""} content1={content_3()}
                                       image1={IMG_3}/>
                </div>
            </div>
        </div>)
}