import HeaderSolutionsEx from "../../components/Solutions/HeaderSolutionsEx";
import BannerSolutions from "../../components/Solutions/BannerSolutions";
import SmallSquareComponent from "../../components/Solutions/SmallSquareComponent";


import IMG_1 from "../../assets/2022/06/pilotageunifie.png"; 
import IMG_2 from "../../assets/2022/06/vuesax-linear-people.png";  

export default function Pilotage () {

    const content_1 = () => {
        return (
            <>

                <p> La fonction première de <b>Swatix</b> est de démultiplier les performances du marketing numérique des Marques :

                </p>

                <ul>
                    <li>
                        Vision 360° de l’écosystème Web Marketing
                    </li>
                    <li>
                        Amélioration des marges opérationnelles des ressources consacrées
                    </li>

                    <li>
                        Optimisation du R.O.A.S.
                    </li>

                    <li>
                        Démultiplication de votre R.O.I multicanal
                    </li>

                    <li>
                        Développement additionnel du C.A
                    </li>
                </ul>
                <p>
                    Avec <b>Swatix</b>, chaque marque dispose d’un outil référentiel analytique exhaustif, univoque et totalement indépendant de tiers pour suivre et piloter au plus près l’ensemble des interactions avec vos utilisateurs.




                </p>
            </>
        )
    }

    return (
<div>
    <HeaderSolutionsEx title={"PILOTAGE UNIFIÉ"} subtitle={"Vision 360° du ROI Webmarketing"} content1={content_1()} image1={IMG_1}/>
    <BannerSolutions content={"Swatix permet de croiser les performances MÉTIERS avec les performances MEDIA pour piloter et optimiser les performances de vos investissements numériques."}/>
    <div style={{backgroundColor:"#f1f4f9", padding: 50, margin: "auto"}}>

        <p style={{maxWidth: "60%", margin: "auto", marginTop: 20, marginBottom: 50, lineHeight: "2em"}}>
            <h2 style={{margin:""}}>
                Un outil d’aide à la prise de décision stratégique

            </h2>
            Qu’il s’agisse de mesurer la portée, les investissements, les coûts et gains, les clics ou les conversions, les TdB et rapports analytiques Swatix sont conçus pour satisfaire TOUS LES NIVEAUX de lecture et d’analyse, faciliter la prise de décision, optimiser ses investissements.
        </p>
        <div style={{display: "flex", flexDirection: "row", gap: 50, justifyContent: "center", }}>
            <SmallSquareComponent content={"Utilisateurs illimités & gestion des rôles"} image={IMG_2}/>
            <SmallSquareComponent content={"Rapports et analyses de synthèse périodiques"} image={IMG_2}/>
            <SmallSquareComponent content={"Dataviz 360° exhaustivité et précision des analyses"} image={IMG_2}/>
            <SmallSquareComponent content={"Reportings clés en main et automatisés"} image={IMG_2}/>
            <SmallSquareComponent content={"Support web Analyst"} image={IMG_2}/>
        </div>
    </div>

</div>
    )
}