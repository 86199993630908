import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "../pages/Homepage";
import Layout from "../components/Layout";

import Ecommerce from "../pages/tableauxDeBord/Ecommerce";
import Emailing from "../pages/tableauxDeBord/Emailing";
import Publicite from "../pages/tableauxDeBord/Publicite";
import Referencement from "../pages/tableauxDeBord/Referencement";
import Reseaux from "../pages/tableauxDeBord/Reseaux";
import Donnee from "../pages/Solutions/Donnee";
import Patrimoine from "../pages/Solutions/Patrimoine";
import Pilotage from "../pages/Solutions/Pilotage";
import Rgpd from "../pages/Solutions/Rgpd";
import Buisness from "../pages/Offres/Buisness";
import Conseils from "../pages/Offres/Conseils";
import Ecom from "../pages/Offres/Ecom";
import Init from "../pages/Offres/Init";
import Analytics from "../pages/tableauxDeBord/Analytics";
import Demo from "../pages/Demo";
import Pricing from "../pages/Pricing";
import MentionLegal from "../pages/MentionLegal";
import PersonnalData from "../pages/PersonnalData";


export default function RouterManager() {
  return (

      <Layout>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/e-commerce" element={<Ecommerce />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/emailing-automation" element={<Emailing />} />
          <Route path="/publicites-sea-sma" element={<Publicite />} />
          <Route
            path="/referencement-naturel"
            element={<Referencement />}
          />
          <Route path="/reseaux-sociaux" element={<Reseaux />} />
          <Route path="/exhaustivite-de-la-donnee" element={<Donnee />} />
          <Route path="/patrimoine-data" element={<Patrimoine />} />
          <Route path="/pilotage-unifie" element={<Pilotage />} />
          <Route path="/rgpd-by-design" element={<Rgpd />} />
          <Route path="/business" element={<Buisness />} />
          <Route path="/conseils" element={<Conseils />} />
          <Route path="/e-com" element={<Ecom />} />
          <Route path="/init" element={<Init />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/protection-des-donnees-personnelles" element={<PersonnalData/>}/>
          <Route path="/MentionLegal" element={<MentionLegal/>}/>
        </Routes>
      </Layout>

  );
}
