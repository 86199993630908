import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Container, Paper, Grid, TextField, Divider, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel'; 
import Stack from '@mui/material/Stack';
import PrincingTable from "./pricing.json";
import Switch from '@mui/material/Switch';
import InfoIcon from '@mui/icons-material/Info';
import {MaterialUISwitch} from "./CustomSwitch"

import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';   
import Box from '@mui/material/Box';

import Header from "../../components/Analytics/Header"; 
import { useNavigate } from "react-router-dom";

import './pricing.css'

const test = () => {
  return <span style={{color:"#1b7afa", fontSize:25}} >
    Swatix Web Marketing à travers des PACKS différenciés pour piloter et maîtriser <br/> vos coûts et investissements WEB
        </span>
}


function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}
ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const marks = [
  {
    value: 0,
  },
  {
    value: 20,
  },
  {
    value: 37,
  },
  {
    value: 100,
  },
];

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-markLabel': {
    fontWeight: 700
  },
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));


const PricingPage = () => {
  const [companyValue, setCompanyValue] = useState(50);
  const [socialMediaValue, setSocialMediaValue] = useState(0);
  const [EmailingValue, setEmailingValue] = useState(0);
  const [adsValue, setAdsValue] = useState(0);
  const [ecommerceEnabled, setEcommerceEnabled] = useState(false);
  const [isPremium, setisPremium] = useState(false);

  const handleChangePremiumPack = (event, newValue) => { 
    setisPremium(!isPremium);
  };

  const handleCompanyValueChange = (event, newValue) => {
    setCompanyValue(newValue);
  };

  const handleEmailingValueChange = (event) => {
    setEmailingValue(event.target.value);
  };

  const handleSocialMediaValueChange = (event) => {
    setSocialMediaValue(event.target.value);
  };

  const handleAdsValueChange = (event) => {
    setAdsValue(event.target.value);
  };

  const handleEcommerceChange = (event) => {
    setEcommerceEnabled(event.target.checked);
  };

  const formatCompanyValue = (value) => {
     if (value === 50) {
      return '0-50k';
    } else if (value === 100) {
      return '50k-100k';
    } else if (value === 150) {
      return '100k-250K';
    } else {
      return 'devis';
    }
  };

  const getPrice = (value) => {
    const range = PrincingTable;

    const selectedRange = range.find((item) => item.value === value);
    return selectedRange ? 
    !isPremium ? selectedRange.price : selectedRange.pricePremium : 'devis';
  };

  const getEmailingPrice = (value, companyValue) => {
    const range = PrincingTable;

    const selectedRange = range.find((item) => item.value === companyValue);
    if (selectedRange && selectedRange.emailing) {
      const selectedEmailing = selectedRange.emailing.find((item) => item.value === parseInt(value));
      return selectedEmailing ? selectedEmailing.price : 'devis';
    }
    return 'devis';
  };
  const getSocialMediaPrice = (value, companyValue) => {
    const range = PrincingTable;

    const selectedRange = range.find((item) => item.value === companyValue);
    if (selectedRange && selectedRange.reseaux_sociaux) {
      const selectedSocialMedia = selectedRange.reseaux_sociaux.find((item) => item.value === parseInt(value));
      return selectedSocialMedia ? selectedSocialMedia.price : 'devis';
    }
    return 'devis';
  };

  const getAdsPrice = (value, companyValue) => {
    const range = PrincingTable;

    const selectedRange = range.find((item) => item.value === companyValue);
    if (selectedRange && selectedRange.ads) {
      const selectedSocialMedia = selectedRange.ads.find((item) => item.value === parseInt(value));
      return selectedSocialMedia ? selectedSocialMedia.price : 'devis';
    }
    return 'devis';
  };

  const getEcommercePrice = () => {
    const range = PrincingTable;

    const selectedRange = range.find((item) => item.value === companyValue);
    if (selectedRange && selectedRange.ecommerce) {
      const selectedEcommerce = selectedRange.ecommerce.find((item) => item.status === ecommerceEnabled);
      return selectedEcommerce ? selectedEcommerce.price : 'devis';
    }
    return 'devis';
  };





  const calculateTotal = () => {
    const companyPrice = getPrice(companyValue);
    const socialMediaPrice = getSocialMediaPrice(socialMediaValue, companyValue);
    const EmailingPrice = getEmailingPrice(EmailingValue, companyValue);
    const adsPrice = getAdsPrice(adsValue, companyValue);
    const ecommercePrice = getEcommercePrice();

    if (companyPrice === 'devis' || socialMediaPrice === 'devis' || EmailingPrice === 'devis' || adsPrice === 'devis' || ecommercePrice === 'devis')
      return 0

    const prices = [companyPrice, socialMediaPrice, adsPrice, ecommercePrice, EmailingPrice].filter((price) => price !== 'devis');
    const total = prices.reduce((sum, price) => sum + price, 0);

    return total !== "0" ? total : 0;
  };



  const calculateTotalMarketing = () => { 
    const socialMediaPrice = getSocialMediaPrice(socialMediaValue, companyValue);
    const EmailingPrice = getEmailingPrice(EmailingValue, companyValue);
    const adsPrice = getAdsPrice(adsValue, companyValue);
    const ecommercePrice = getEcommercePrice();
    
    if (  socialMediaPrice === 0 && EmailingPrice === 0 && adsPrice === 0 && ecommercePrice === 0)
      return -1

    if (  socialMediaPrice === 'devis' || EmailingPrice === 'devis' || adsPrice === 'devis' || ecommercePrice === 'devis')
      return 0

    const prices = [ socialMediaPrice, adsPrice, ecommercePrice, EmailingPrice].filter((price) => price !== 'devis');
    const total = prices.reduce((sum, price) => sum + price, 0);

    return total;
  };







  const navigate = useNavigate();
  return (

    <>
    <div id="simulatorPrice" >


      <Container maxWidth="lg" style={{   boxShadow: "rgb(185, 207, 237) 0px 0px 8px", marginBottom: 10, paddingTop: 35, borderRadius: 7 }}>
        
      <Typography variant="h4" align="center" gutterBottom style={{
          marginBottom: 30,
          background: "white"
        }} >
        Simulateur d'offre d'abonnement à Swatix
        </Typography>
              <Divider style={{
          marginBottom: 30
        }}/>
        
      <Typography variant="h4" align="center" gutterBottom style={{
          marginBottom: 30, 
          textAlign:"center"
        }} > 
          <Stack direction="row" spacing={1} alignItems="center" fullWidth style={{
            padding: "0% 30%"
          }} >
            <Typography>Package Analytics Starter</Typography>
            <FormControlLabel
              control={<MaterialUISwitch sx={{ m: 1 }} 
              value={isPremium} 
              onChange={(e) => {
                handleChangePremiumPack(e, e.target.value)
              }}
              />}
              label=""
            />
            <Typography>Package Analytics Premium</Typography>
          </Stack>
          
        </Typography>
              <Divider style={{
          marginBottom: 30
        }}/>

      <Grid item xs={12} md={12}>
 
      <Typography variant="h6" align="center" gutterBottom style={{
          marginBottom: 30,
          background: "white"
        }} >
        Pack Web Analytics
        </Typography>
              <Divider style={{
          marginBottom: 30
        }}/>
      </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography variant="span" align="left" gutterBottom>
              Volume de trafic mensuel

              <Tooltip className='tooltip-pricing-label' title={
                <div>
                  Le volume de trafic est mesuré sur l'ensemble des pages vu sur le site web<br /><br />
                  **Nous vous offrons la possibilité de récupérer l'historique de Google Analytics, ainsi qu'une modélisation complète de votre dashboard Google Analytics ( disponibles prochainement )
                </div>
              }>
                <InfoIcon />
              </Tooltip>
            </Typography>
          </Grid>



          <Grid item xs={12} md={12}> 

            <IOSSlider
              aria-label="ios slider"
              defaultValue={50}
              step={0}
              marks={[ 
                { value: 50, label: '0-50k hits', price: 21 },
                { value: 100, label: '50k-100k hits', price: 35 },
                { value: 150, label: '100k-250k', price: 60 },
                { value: 200, label: '250k-500k hits', price: 'devis' }, 
                { value: 250, label: '500k-1M hits', price: 'devis' }, 
                { value: 300, label: '> 1M hits', price: 'devis' },  
              ]}
              min={30}
              max={330} 
              value={companyValue}
              onChange={handleCompanyValueChange}
            />
          </Grid> 

 
          <Grid item xs={12} md={3} style={{
            marginTop: "-10px",
            paddingBottom: 10
          }}  > 
            <Typography variant="h6" align="left" gutterBottom>
              Tarif mensuel <span style={{color:"grey", fontSize:14}} >(*)</span> :
            </Typography>
          </Grid> 
          <Grid item xs={12} md={9} style={{
            marginTop: "-10px"
          }}  > 
            <Typography variant="h5" align="left" gutterBottom >
              <label style={{ 
                fontSize: "18px",
                border: "1.5px solid #2c93cf",
                fontWeight: 600,
                padding: "4px 30px",
                borderRadius: "7px"
              }} >
                {getPrice(companyValue) !== "devis" ? getPrice(companyValue) + " €" : "DEVIS"} 
              </label>
              <label style={{ 
                  color:" #9d9d9d",
                  fontSize: "14px",
                  marginLeft: "30px",
                  fontWeight: 600,
                  
              }} >
                {formatCompanyValue(companyValue) !== "devis" ? formatCompanyValue(companyValue) + " Pages vues / mois"  : ""}
              </label>

              {formatCompanyValue(companyValue) === "devis" && 
              <label style={{
                fontSize: "16px",
                color: "#ffffff",
                borderRadius: "7px",
                padding:" 7px 33px",
                cursor: "pointer",
                background: "#1b7afa",
                boxShadow: "1px 1px 1px #1b7afa",
              }} onClick={() => {
                  navigate("/demo");
              }} >
                Nous contacter
              </label> }
              
            </Typography>
          </Grid>


          <Grid item xs={12} md={12} style={{
            marginBottom:100
          }} >

          <br />
          <Typography variant="div" align="center" gutterBottom style={{ 
            }} >
            
            {!isPremium ? 
            <div style={{
              fontSize:14.5,
              color:"black"
            }} >
              <div style={{color:"#1b7afa", fontSize: 18, fontWeight: 700}} >PACK ANALYTICS STARTER</div><br/>
              En optant pour le pack STARTER, vous accédez à des tableaux de bord exhaustifs, simples et compréhensibles<br/>

                qui vous permettent de piloter votre site avec facilité,<br/>

                Des tableaux de bord exhaustifs sur <strong> les visites, les pages, l'acquisition, les médias,<br/>

                l'état de santé de votre site, les interactions & conversions ainsi que les sites référents et les liens sortants</strong> <br /><br />
               
                Allez plus loin encore avec <span style={{color:"#1b7afa", fontWeight: 700, cursor:"pointer"}}>le PACK PREMIUM</span> qui vous donne accès à l’Analytics Google Search + Google My Business

+ l’audit de votre site
              </div>
            :
            <div style={{
              fontSize:14.5,
              color:"black"
            }} >

              <div style={{color:"#1b7afa", fontSize: 18, fontWeight: 700}} >PACK ANALYTICS PREMIUM</div><br/>
              En choisissant le pack Premium, vous bénéficierez d'un accès encore plus étendu à notre tableau de bord puissant.<br/>
              <strong>En plus des fonctionnalités offertes dans le pack Starter</strong>, vous aurez également accès à <strong>une partie d'audit</strong> dédiée à l'analyse approfondie de votre site. <br/>
              De plus, vous pourrez intégrer des outils essentiels tels que <strong> Google Search Console et Google My Business.</strong> <br/> 
              Avec le pack Premium, vous aurez toutes les ressources nécessaires pour optimiser votre site web et renforcer votre positionnement sur Internet.
            </div>
            }
            </Typography>

          </Grid> 
 

        </Grid>
      </Container>
    </div>


    <div className="simulatorPrice-webmarketing-intro" >
    <Header title={""} content1={""}
                    content2={""}
                    content3={""}
                    subTitle={test()}/>
    </div>

    <div id="simulatorPrice" style={{
      marginBottom:100
    }} >


      <Container maxWidth="lg" style={{   boxShadow: "rgb(185, 207, 237) 0px 0px 8px", marginBottom: 10, paddingTop: 35, borderRadius: 7 }}>
         
 

        <Grid container spacing={3}> 

 
        

          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom>
              Réseaux sociaux

              <Tooltip className='tooltip-pricing-label' title={
                <div>
                  Notre plateforme est là pour vous offrir une gestion complète de vos réseaux sociaux. <br /><br />
                  de LinkedIn, Facebook, Twitter à Instagram. <br /><br />
                  De plus, nous offrons des fonctionnalités pour TikTok, Snapchat, Pinterest et WhatsApp, disponibles prochainement
                </div>
              }>
                <InfoIcon />
              </Tooltip>
              
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{
            padding: "1% 19%"
          }} >
            <TextField
              label="Réseaux sociaux"
              type="number"
              value={socialMediaValue}
              onChange={handleSocialMediaValueChange}
              fullWidth
              inputProps={{
                min: 0, // Set the minimum value here
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom>
              {getSocialMediaPrice(socialMediaValue, companyValue) !== 'devis' ? getSocialMediaPrice(socialMediaValue, companyValue) + ' €' : 'Devis'}
              <span
                style={{
                  color: 'grey',
                  fontSize: '12px',
                }}
              >
                {getSocialMediaPrice(socialMediaValue, companyValue) !== 'devis'
                  ? ' '
                  : ' '}
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom>
              Réseaux publicitaires

              <Tooltip className='tooltip-pricing-label' title={
                <div>
                  Notre plateforme est là pour vous offrir une gestion complète de vos réseaux publicitaires. <br /><br />
                  de Google Ads, LinkedIn Ads, Facebook Ads à instagram Ads. <br /><br />
                  De plus, nous offrons des fonctionnalités pour TikTok Ads, Snapchat Ads, Pinterest Ads et WhatsApp Ads, disponibles prochainement
                </div>
              }>
                <InfoIcon />
              </Tooltip>

            </Typography>
          </Grid>
          <Grid item xs={12} md={6}  style={{
            padding: "1% 19%"
          }} >
            <TextField
              label="Réseaux publicitaires"
              type="number"
              value={adsValue}
              onChange={handleAdsValueChange}
              fullWidth 
              inputProps={{
                min: 0, // Set the minimum value here
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom>
              {getAdsPrice(adsValue, companyValue) !== 'devis' ? getAdsPrice(adsValue, companyValue) + ' €' : 'Devis'}
              <span
                style={{
                  color: 'grey',
                  fontSize: '12px',
                }}
              >
                {getAdsPrice(adsValue, companyValue) !== 'devis'
                  ? ' '
                  : ' '}
              </span>
            </Typography>
          </Grid>


          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom>
              Réseaux d'emailing


              <Tooltip className='tooltip-pricing-label' title={
                <div>
                  Notre plateforme est là pour vous offrir une gestion complète de vos réseaux emailing sur SendinBlue (Brevo). <br /><br /> 
                  De plus, nous offrons des fonctionnalités pour Salesforce, mailchimp, Mailgun, disponibles prochainement
                </div>
              }>
                <InfoIcon />
              </Tooltip>

            </Typography>
          </Grid>
          <Grid item xs={12} md={6}  style={{
            padding: "1% 19%"
          }} >
            <TextField
              label="Réseaux d'emailing"
              type="number"
              value={EmailingValue}
              onChange={handleEmailingValueChange}
              fullWidth
              inputProps={{
                min: 0, // Set the minimum value here
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom>
              {getEmailingPrice(EmailingValue, companyValue) !== 'devis' ? getEmailingPrice(EmailingValue, companyValue) + ' €': 'Devis'}
              <span
                style={{
                  color: 'grey',
                  fontSize: '12px',
                }}
              >
                {getEmailingPrice(EmailingValue, companyValue) !== 'devis'
                  ? ' '
                  : ' '}
              </span>
            </Typography>
          </Grid>


          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom >
              eCommerce

              <Tooltip className='tooltip-pricing-label' title={
                <div>
                  Suivez vos sites e-commerce en temps réel grâce à notre tableau de bord personnalisé. <br/><br/>
                   Et ce n'est pas tout ! Profitez également de notre version premium sur devis pour une vision encore plus approfondie de vos données
                </div>
              }>
                <InfoIcon />
              </Tooltip>

            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{
            padding: "1% 22%"
          }} >
            <Switch
              checked={ecommerceEnabled}
              onChange={handleEcommerceChange}
              inputProps={{ 'aria-label': 'ecommerce-switch' }}
              classes={{
                root: 'switch-root',
                switchBase: 'switch-base',
                thumb: 'switch-thumb',
                track: 'switch-track',
                checked: 'switch-checked',
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="span" align="left" gutterBottom>
              {getEcommercePrice() !== 'devis' ? getEcommercePrice() + ' €' : 'Devis'}
              <span
                style={{
                  color: 'grey',
                  fontSize: '12px',
                }}
              >
                {getEcommercePrice() !== 'devis'
                  ? ' '
                  : ' '}
              </span>
            </Typography>
          </Grid>



          <Grid item xs={12} md={12} style={{
            padding: "15px 20px"
          }} > 
            <Divider style={{ 
            }}/> 
          </Grid>

          <Grid item xs={12} md={3} style={{
            marginTop: "-20px",
            paddingBottom: 10
          }}  > 
            <Typography variant="h6" align="left" gutterBottom>
              Total Marketing
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}> 
          </Grid>
          <Grid item xs={12} md={3} style={{
            marginTop: "-20px"
          }}  > 
            <Typography variant="h6" align="left" gutterBottom style={{color:"#1b7afa"}} >
              {calculateTotalMarketing() !== 0 ? calculateTotalMarketing() !== -1 ? calculateTotalMarketing() + " € / Mois" : '0 € / Mois' : "DEVIS"} 
            </Typography>
          </Grid>


          <Grid item xs={12} md={12} style={{
            padding: "15px 20px"
          }} > 
            <Divider style={{ 
            }}/> 
          </Grid>

          <Grid item xs={12} md={5} style={{
            marginTop: "-20px",
            paddingBottom: 10
          }}  > 
            <Typography variant="h6" align="left" gutterBottom>
              Total (Analytics + Marketing)
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}> 
          </Grid>
          <Grid item xs={12} md={3} style={{
            marginTop: "-20px"
          }}  > 
            <Typography variant="h6" align="left" gutterBottom style={{color:"#1b7afa"}} >
              {calculateTotal() !== 0 ? calculateTotal() + " € / Mois" : "DEVIS"} 
            </Typography>
          </Grid>

        </Grid>
      </Container>
    </div>

    </>
  );
};

export default PricingPage;
