
import "./FluxButtons/fluxButton.css" 
import OAuth2Login from 'react-simple-oauth2-login';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google'; 
import React from "react"

// declaration simple du bouton
const GoogleLoginButton = ({ onClick, typeValidation }) => (  
    <button class="google-button"  onClick={onClick} >
        <div class="google-icon-wrapper">
            <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
        </div>
        <span class="button-text">Connecter son compte Google 
        {typeValidation === 1 && <label> Ads </label> }
        {typeValidation === 2 && <label> Search Console </label> }
        {typeValidation === 3 && <label> MyBusiness </label> }
        {typeValidation === 4 && <label> Analytics </label> }
         à Swatix </span>
    </button>
);
export default function SquareCardCustom ({title, content, logo, listMode = false, heightLogo , widthLogo, buttonConnexionValidator=false, typeValidation}) {
    // Redirect URI for validation API
    const CLIENT_REDIRECT_URI = `${window.location.origin}/`
    // response / error callback api
    const responseGoogle = (response) => {
        console.log(response);  
    }
    const responseGoogleError = (response) => {
        console.log(response); 
    } 
    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };


    const [scope, setScope] = React.useState("")
    const [clientId, setclientId] = React.useState("")

    React.useEffect(() => {

        if (typeValidation === 1 ){
            setScope("https://www.googleapis.com/auth/adwords")
            setclientId("603517338808-utb64iogqt8dc05dib6mbhuvtvlj1k9d.apps.googleusercontent.com")
        }
        if (typeValidation === 2 ){
            setScope("https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/webmasters.readonly")
            setclientId("1061274116595-1lu71jd17ctujltdeidk3asrdsf12pji.apps.googleusercontent.com")
        }
        if (typeValidation === 4 ){
            setScope("https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.manage.users.readonly https://www.googleapis.com/auth/analytics.provision https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics.user.deletion")
            setclientId("169988304155-mkmce8tv6fe6mtvs52s345ud5muj9s8s.apps.googleusercontent.com")
        }
    }, [])

    return (
        <div style={{boxShadow: "0px 6px 18px -6px rgba(0,0,0,0.3)", display: "block", overflow: "hidden", flex: 1, borderRadius: 25, marginRight: 20, backgroundColor: "white",marginLeft: 20, paddingBottom: 20,paddingRight:20, paddingLeft: 20}}>
            <div style={{}}>
                <span style={{}}>
                        <img decoding="async" width={widthLogo} height={heightLogo}
                            src={logo} alt="" title="Groupe 1674"
                            style={{marginTop: 10}}/>
                </span>
            </div>
            <div>
                <div style={{marginRight: 40, paddingRight: 20, textAlign: "left", marginBottom: 10}}>
                    <h3 style={{fontWeight: listMode? 800: 600, fontSize: 20, color: listMode ? "#1B7AFA" : "black", marginLeft: listMode? 20 : 0}}>{title}</h3>
                </div>
            </div>
            <div>
                <div style={{lineHeight: 2, textAlign: "left"}}>
                    <p>{content}</p>
                </div>
            </div>
            {/** Section pour valider les Api de connexion automatique */}
            {buttonConnexionValidator && 
            <div> 

                        {/** google button */}
                        <a href="https://developers.google.com/terms/api-services-user-data-policy" style={{color:"black"}} >Politique de données des utilisateurs des services API de Google </a> <br /> <br />
                        <OAuth2Login
                            authorizationUrl="https://accounts.google.com/o/oauth2/v2/auth"  // auth url for google
                            responseType="code" // code - token
                            clientId={clientId}  // client ID from google cloud project
                            redirectUri={CLIENT_REDIRECT_URI}
                            onSuccess={responseGoogle} // response, add responseSuccess
                            onFailure={responseGoogleError} // response add responseError
                            scope={scope} // scoop used for google ads
                            render={({ onClick }) =>  <GoogleLoginButton onClick={onClick} typeValidation={typeValidation} />} // custom button
                            extraParams={{  // extra params
                            prompt: 'consent',
                            access_type : 'offline'
                            }}
                        />

                        {/** google button */}
                        {typeValidation === 2 &&
                         <OAuth2Login
                            authorizationUrl="https://accounts.google.com/o/oauth2/v2/auth"  // auth url for google
                            responseType="code" // code - token
                            clientId={"236746595893-chtggeokkv6dmheskigib87ecbjf03jf.apps.googleusercontent.com"}  // client ID from google cloud project
                            redirectUri={CLIENT_REDIRECT_URI}
                            onSuccess={responseGoogle} // response, add responseSuccess
                            onFailure={responseGoogleError} // response add responseError
                            scope={"https://www.googleapis.com/auth/business.manage"} // scoop used for google ads
                            render={({ onClick }) =>  <GoogleLoginButton onClick={onClick} typeValidation={3} />} // custom button
                            extraParams={{  // extra params
                            prompt: 'consent',
                            access_type : 'offline'
                            }}
                        />}
                        {/** facebook button */}                  
                        <button class="facebook-button">
                            <i class="fab fa-facebook-f"></i>
                            <span class="button-text">Se connecter avec Facebook</span>
                        </button>
                        {/** instagram button  */}
                        <button class="instagram-button">
                            <i class="fab fa-instagram"></i>
                            <span class="button-text">Se connecter avec Instagram</span>
                        </button>
                        {/** twitter button */}
                        <button class="twitter-button">
                            <i class="fab fa-twitter"></i>
                            <span class="button-text">Se connecter avec Twitter</span>
                        </button>
                        
            </div>
            }
            
        </div>
    )
}