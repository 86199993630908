
import IMG_6 from "../../assets/2022/06/connecteurs.png";

export default function Section2({}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center", 
        backgroundImage: `url(${IMG_6})`,
        backgroundSize: "cover",
        height: "600px",
        minHeight: "600px",
        maxHeight: "600px",
        marginTop: "40px"
      }}
    >
      <div style={{ alignItems: "left", width: "80%" }}>
        <h2 style={{ color: "#1B7AFA", textTransform: "uppercase" }}>écosystème numérique</h2>
        <h1 style={{ color: "white" }}>30 + sources de données disponibles</h1>
        <h4 style={{ color: "white" }}>Les équipes Swatix ont développé plus de 30 connecteurs auprès des plateformes collectrices de données communément <br/>utilisées par les Marques.</h4>
        <h4 style={{ color: "white" }}> Swatix a pris le parti de développer ses propres connecteurs pour garantir aux Marques la constitution d’un Patrimoine <br/> DATA exhaustif, homogénéisé afin d’optimiser le potentiel d’exploitation de  toutes les ressources web marketing mobilisées.</h4>
      </div>
    </div>
  );
}
