import React from 'react';
import {Link} from "react-router-dom";

function SavoirPlus(props) {
  const buttonStyle = {
    color: '#1B7AFA',
    borderWidth: '0px',
    borderRadius: '0px',
    letterSpacing: '0px',
    fontSize: '16px',
    fontWeight: '600',
    textDecoration: 'underline',
    transition: 'all 0.2s ease-in-out',
  };

  return (
    <Link to={props.link} className='savoirPlus' style={buttonStyle}>En Savoir +</Link>
  );
}

export default SavoirPlus;