import React from "react";
import HomeHeader from "../components/Homepage/HomeHeader";
import Header from "../components/Analytics/Header";
import Section1 from "../components/Homepage/Section1";
import Section2 from "../components/Homepage/Section2";
import Section3 from "../components/Homepage/Section3";
import PatrimoineDataHomepage from "../components/Homepage/PatrimoineDataHomepage";
export default function Homepage() {
  return (
    <div>
      <HomeHeader
        main1={"Solution souveraine au service exclusif"}
        main2={"de l’optimisation des investissements Web Marketing"}
        content1={
          "Swatix centralise dans une interface unique toutes les données analytics."
        }
        content2={
          "À vous de piloter ce qui sert véritablement à développer votre chiffre d’affaires."
        }
      />

      <div>
        <div style={{ backgroundColor: "black", height: "40px" }}></div>
        <Header
          title={" "}
          content1={
            "L’exhaustivité de la donnée combinée à la puissance d’analyse de Swatix."
          }
          content2={"Des reportings modélisés, user friendly, permettant un accès direct aux KPIs et à la performance."}
          content3={"Conçu pour les usages des équipes Data, Marketing et Produits de l’entreprise."}
          subTitle={
            "Tableaux de bord nouvelle génération au service exclusif de l’Économie de l’entreprise"
          }
        />
        <Section1 />
        <Section2 />
        <div style={{ backgroundColor: "#f3f8ff" }}>
          <Header
            title={"web analytics 3.0"}
            content1={""}
            content2={""}
            subTitle={""}
          />
          <Section3 />

        </div>
        <PatrimoineDataHomepage/>
      </div>
    </div>
  );
}
