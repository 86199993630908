import React, { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

const LinkDecorator = () => {
  const location = useLocation();

  useEffect(() => {
    const domainsToDecorate = ['swatix.eu'];

    const queryParams = ['mtm_source', 'mtm_medium', 'mtm_campaign'];

    const decorateUrl = (urlToDecorate) => {
      urlToDecorate = urlToDecorate.indexOf('?') === -1 ? urlToDecorate + '?' : urlToDecorate + '&';
      const collectedQueryParams = [];
      for (let queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {
        const paramValue = getQueryParam(queryParams[queryIndex]);
        if (paramValue) {
          collectedQueryParams.push(`${queryParams[queryIndex]}=${paramValue}`);
        }
      }
      return urlToDecorate + collectedQueryParams.join('&');
    };

    const getQueryParam = (name) => {
      if (name) {
        const regex = new RegExp(`[?&]${encodeURIComponent(name)}=([^&]*)`);
        const matches = regex.exec(location.search);
        if (matches && matches.length > 1) {
          return decodeURIComponent(matches[1]);
        }
      }
      return null;
    };

    const decorateLinks = () => {
      const links = document.querySelectorAll('a');
      links.forEach((link) => {
        const href = link.getAttribute('href');
        if (href && !href.startsWith('#')) {
          const shouldDecorate = domainsToDecorate.some((domain) => href.includes(domain));
          if (shouldDecorate) {
            link.href = decorateUrl(href);
          }
        }
      });
    };

    decorateLinks();
    window.addEventListener('popstate', decorateLinks);

    return () => {
      window.removeEventListener('popstate', decorateLinks);
    };
  }, [location]);

  return null;
};

export default LinkDecorator;
