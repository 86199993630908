import SquareCardCustom from "../SquareCardCustom";
import React, { useState } from "react";
import GridSquareCardCustom from "../GridSquareCardCustom";
import InterractiveImage from "../InterractiveImage";
import InformationHeader from "../InformationHeader";

import IMG_1 from "../../assets/2022/06/Groupe-1672.svg";
import IMG_2 from "../../assets/2022/06/Groupe-1673.svg";
import IMG_3 from "../../assets/2022/06/Groupe-1674.svg";
import IMG_4 from "../../assets/2022/06/Groupe-1675.svg";
import IMG_5 from "../../assets/2022/06/Groupe-1676.svg";
import IMG_6 from "../../assets/2022/06/Groupe-1677.svg";
import IMG_7 from "../../assets/2022/06/analytics_demo.png";


const row_1 = [
  {
    content:
      "Qui sont les visiteurs, quels supports utilisent-ils et comment naviguent-ils dans l’écosystème numérique de la Marque ?",
    title: "Comprendre les audiences et leurs usages",
    logo: IMG_1,
  },
  {
    content:
      "8 secondes d’attention moyenne : les contenus de Marque captent-ils réellement l’attention des utilisateurs ?",
    title: "Visualiser la performance des contenus",
    logo: IMG_2,
  },
  {
    content:
      "Sur quels canaux de l’écosystème numérique faut il stratégiquement mobiliser les ressources disponibles ?",
    title: "Identifier les sources de trafics qui génèrent des performances",
    logo: IMG_3,
  },
];

const row_2 = [
  {
    content:
      "Évaluez et optimisez  le funnel de conversion en identifiant les interactions de chacune des étapes relationnelles.",
    title: "Suivre tous les évènements d’interaction avec les utilisateurs\n",
    logo: IMG_4,
  },
  {
    content:
      "L’expérience utilisateur repose également sur la capacité à délivrer l’information dans les meilleures conditions.",
    title: "Monitorer l’état de santé du site & du serveur",
    logo: IMG_5,
  },
  {
    content:
      "Croisez le parcours utilisateur avec le funnel de conversion pour tirer le meilleur potentiel des canaux  utilisés.",
    title: "Mesurer et attribuer les conversions",
    logo: IMG_6,
  },
];
// Youcef - ADD validation button for google api - Google Ads -
const row_3 = [
    {
        content: "",
        title: "Connectez vos sources d'acquisition rapidement via des connecteurs customiser \n",
        logo: ""
    }
]


export default function AnalyticsDemo({}) {
  return (
    
    <div>
      <InterractiveImage
        image={
          IMG_7
        }
      />

      <div>
        <InformationHeader content1={null} content2={null} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            marginTop: 50,
            margin: "auto",
            position: "relative",
            gap: 30,
            paddingBottom: 30,
          }}
        >
          <GridSquareCardCustom listCard={row_1} />
          <GridSquareCardCustom listCard={row_2} />
          {/** Section pour valider les Api de connexion automatique */}
          <GridSquareCardCustom listCard={row_3} buttonConnexionValidator={true} typeValidation={4} />
        </div>
      </div>
    </div>
  );
}
