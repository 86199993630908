import axios from "axios";

const api_url =  process.env.NODE_ENV === "development"
    ? "http://localhost:9973"
    : "";

    
function build_path(...args) {
    return args
        .map((part, i) => {
            if (i === 0) {
                return part.toString().trim().replace(/[/]*$/g, "");
            } else {
                return part.toString().trim().replace(/(^[/]*|[/]*$)/g, "");
            }
        })
        .filter((x) => x.length)
        .join("/");
};

    
 
export default async function contactForm(params) { 
    
     
    return await axios.post(
        "/mail/contactMessageSwatix",
        params,
        { withCredentials: false }
        );
}
 