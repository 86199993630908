import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


export default function MentionLegal() {
  return <div style={{display:"flex",flexDirection:"column",width:"80%",margin:"auto",position:"relative", fontFamily:"Montserrat', sans-serif", fontSize:13}}>

    <h1>PROTECTION DES DONNÉES</h1>

    <h2 style={{color:"#1B7AFA"}}>1 : Politique de protection des données à caractère personnel</h2>

    <p>La présente politique décrit la manière dont Swatix traite les données à caractère personnel (ci-après les « Données Personnelles ») collectées auprès de ses prospects, clients et partenaires (ci-après le/les « Clients ») : <br /><br />

    <FiberManualRecordIcon className="pricing-table-dot"/> lors de leur navigation sur les sites Internet https://swatix.eu   (ci-après les « Sites ») et de leur utilisation des fonctionnalités de ces Sites ;<br />
    <FiberManualRecordIcon className="pricing-table-dot"/> lors de prises de contacts téléphoniques ;<br />
    <FiberManualRecordIcon className="pricing-table-dot"/> dans le cadre de la formalisation de relations contractuelles (signature de contrats , etc.) et du suivi de ces relations contractuelles ;<br />
    <FiberManualRecordIcon className="pricing-table-dot"/> par tout autre moyen.<br /><br />
     
    Les textes de référence de cette politique sont le Règlement Général pour la Protection des Données n° 2016/679/UE du 27 avril 2016 – applicable depuis le 25 mai 2018 (le « RGPD ») et la loi dite « Informatique et Libertés » du 6 janvier 1978 modifiée (la « LIL »).<br />

    <br />Cette politique pourra être modifiée, complétée ou mise à jour afin notamment de se conformer à toute évolution légale, réglementaire, jurisprudentielle et technique. Cependant, les Données Personnelles des Clients seront toujours traitées conformément à la politique en vigueur au moment de leur collecte, sauf si une prescription légale impérative venait à en disposer autrement et était d’application rétroactive.
    </p>

    <h2 style={{color:"#1B7AFA"}}>2 : Les finalités principales des Données Personnelles collectées sont les suivantes :</h2>
    <p><FiberManualRecordIcon className="pricing-table-dot"/>Sur le site https://swatix.eu dans le cadre des formulaires de prise de contact : nom, prénom, numéro de téléphone, adresse électronique, message laissé par le Client le cas échéant.</p>
    <p><FiberManualRecordIcon className="pricing-table-dot"/>Dans le cadre de la prospection commerciale : nom, prénom, adresse postale, adresse électronique, numéro de téléphone.</p>
    <p><FiberManualRecordIcon className="pricing-table-dot"/>Dans le cadre de la formalisation et du suivi des relations contractuelles : nom, prénom, adresse postale, adresse électronique, numéro de téléphone.</p>

    <p> <strong>Swatix</strong> confirme ne collecter aucune donnée « sensible » au sens de l’article 9 paragraphe 1 du RGPD (par exemple, données concernant la santé).</p>
   
    <h2 style={{color:"#1B7AFA"}}>3 : Les Données Personnelles des Clients sont conservées</h2>
    <p>Les Données Personnelles des Clients sont conservées pendant une durée qui ne saurait excéder la durée de la démarche commerciale sauf si celle-ci débouche sur la signature d’un contrat, auquel cas elles sont conservées pendant une durée qui ne saurait excéder une période de 5 ou 10 ans à compter de la fin du contrat selon la nature des Données Personnelles, sauf si :</p>
    <p><FiberManualRecordIcon className="pricing-table-dot"/>Le Client exerce, dans les conditions prévues ci-après, l’un des droits qui lui sont reconnus par la législation.</p>
    <p><FiberManualRecordIcon className="pricing-table-dot"/>Une durée de conservation plus longue est autorisée ou imposée en vertu d’une disposition légale ou réglementaire.</p>
  
  
    <h2 style={{color:"#1B7AFA"}}>4. En matière de sécurité et de confidentialité :</h2>
    <p>En matière de sécurité et de confidentialité, <strong>Swatix</strong> met en œuvre des mesures organisationnelles, techniques et logicielles pour protéger les Données Personnelles contre les altérations, destructions et accès non autorisés.</p>
  
    <h2 style={{color:"#1B7AFA"}}>5. L’accès à vos Données Personnelles est strictement limité</h2>
    <p>L’accès à vos Données Personnelles est strictement limité aux employés et préposés de <strong>Swatix</strong>, habilités en raison de leurs fonctions et tenus à une obligation de confidentialité. Cependant, les Données Personnelles collectées pourront éventuellement être communiquées à des sous-traitants chargés contractuellement de l’exécution des tâches nécessaires au bon fonctionnement des Sites et de leurs services ainsi qu’à la bonne gestion de la relation avec le Client, sans que ce dernier ait besoin de donner son autorisation. Il est précisé que, dans le cadre de l’exécution de leurs prestations, les sous-traitants n’ont qu’un accès limité aux Données Personnelles des Clients et ont une obligation contractuelle de les utiliser en conformité avec les dispositions de la législation applicable en matière de protection des Données Personnelles. En dehors des cas énoncés ci-dessus, <strong> Swatix</strong> s’engage à ne pas vendre, louer, céder ou permettre à des tiers d’accéder aux Données Personnelles sans le consentement préalable des Clients, à moins d’y être contraint en raison d’un motif légitime (obligation légale, lutte contre la fraude ou l’abus, exercice des droits de la défense, etc.)</p>
  
  
    <h2 style={{color:"#1B7AFA"}}>6. Les Clients disposent des droits suivants :</h2>
    <p>Les Clients disposent des droits suivants conformément aux dispositions légales et réglementaires applicables, en particulier le RGPD et la LIL :<br/><br/>

      <FiberManualRecordIcon className="pricing-table-dot"/> Un droit d’accès pour connaître les Données Personnelles qui les concernent ;<br/>
      <FiberManualRecordIcon className="pricing-table-dot"/> Un droit de rectification de celles-ci en cas d’inexactitude(s) ;<br/>
      <FiberManualRecordIcon className="pricing-table-dot"/> Un droit de portabilité ou de suppression des Données Personnelles ;<br/>
      <FiberManualRecordIcon className="pricing-table-dot"/> Un droit de limitation du traitement des Données Personnelles ;<br/>
      <FiberManualRecordIcon className="pricing-table-dot"/> Un droit d’opposition au traitement des Données Personnelles ;<br/>
      <FiberManualRecordIcon className="pricing-table-dot"/> Un droit de retrait du consentement à l’utilisation des coordonnées des Clients pour l’envoi d’informations commerciales et de messages publicitaires via courriers électroniques, messages SMS, appels téléphoniques et courriers postaux.<br/><br/>

      Ces différents droits sont à exercer par courriel à l’adresse suivante : rgpd@novagen.tech
      Pour des raisons de sécurité et afin d’éviter toute demande frauduleuse, cette demande devra être accompagnée d’un justificatif d’identité. Le justificatif sera détruit une fois la demande traitée.<br/><br/>

      Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés (plus d’informations sur www.cnil.fr).</p>
        
  


      <h2 style={{color:"#1B7AFA"}}>7. Politique des « Cookies »</h2>
    <p>Un « cookie » est un fichier texte déposé sur le terminal du Client (ordinateur, mobile, tablette) lors de la consultation d’un site Internet. Il enregistre des informations relatives à la navigation du Client sur les Sites et permet à son émetteur d’identifier le terminal sur lequel il est enregistré, pendant la durée de validité dudit « cookie ».</p>
    <p>Certains « cookies » sont indispensables à l’utilisation d’un site, d’autres permettent d’optimiser et de personnaliser les contenus affichés.</p>
    <p><strong>Swatix</strong> utilise deux types de « cookies » :</p>
  
    <p>
    <FiberManualRecordIcon className="pricing-table-dot"/>Des « cookies » indispensables à la navigation sur les Sites qui permettent d’en utiliser les fonctionnalités principales et d’en sécuriser la connexion. Ces « cookies » sont exclusivement émis par <strong> Swatix</strong> . En désactivant l’utilisation de ces « cookies », il se peut que le Client ne puisse plus bénéficier de certaines fonctionnalités des Sites ;<br />

    <FiberManualRecordIcon className="pricing-table-dot"/>Des « cookies » analytiques permettant d’établir des statistiques d’utilisation des Sites afin de mieux comprendre la navigation et l’intérêt que les Clients portent aux services de Swatix . A cet égard, <strong>Swatix</strong> utilisent « Matomo », un outil  d’analyse d’audience Internet permettant de mieux comprendre le comportement des Clients. Cet outil utilise des « cookies » pour collecter des informations anonymes et générer des rapports sur les statistiques d’utilisation des Sites (sans identification individuelle).</p>
    
    <p>Par ailleurs, les Clients ont la possibilité de s’opposer ou de limiter l’enregistrement des « cookies » en configurant leur logiciel de navigation. Il convient de se reporter à la rubrique d’aide du navigateur Internet pour tous les détails techniques sur les options disponibles concernant l’enregistrement de « cookies » sur un terminal. Pour de plus amples informations sur la gestion des « cookies », le Client peut se référer à la page dédiée du site de la CNIL : https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</p>
    <p>Il est néanmoins important de noter que si le Client refuse l’enregistrement de certains « cookies », ceci pourra affecter le bon fonctionnement de certaines fonctionnalités des Sites.</p>

  </div>;
}
