import Header from "../../components/Analytics/Header";
import InterractiveImage from "../../components/InterractiveImage";
import InformationHeader from "../../components/InformationHeader";
import GridSquareCardCustom from "../../components/GridSquareCardCustom";
import React from "react";

import IMG_1 from "../../assets/2022/06/Groupe-1674.svg"; 
import IMG_2 from "../../assets/2022/06/Groupe-1673.svg";  
import IMG_3 from "../../assets/2022/06/Groupe-1680.svg";  
import IMG_4 from "../../assets/2022/06/Groupe-1671.svg";  
import IMG_5 from "../../assets/2022/06/nl_demo.png";  

const row_1 = [
    {
        content: "",
        title: "Suivre et optimiser les performances de routage de vos campagnes",
        logo: IMG_1
    },
    {
        content: "",
        title: "Identifier les campagnes qui génèrent des performances",
        logo: IMG_2
    },

]

const row_2 = [    {
    content: "",
    title: "Développer la connaissance client et démultiplier la segmentation des campagnes\n",
    logo:IMG_3
},
    {
        content: "",
        title: "Mesurer le ROI consolidés des campagnes engagées\n",
        logo:IMG_4
    }]

export default function Emailing () {

    const content1 = () => {
        return <>Un canal puissant au cœur de toutes les phases de la relation client <br/> <b>prospection, conversion, fidélisation.</b></>
    }

    const content1_info = () => {
        return (
            <h2 style={{fontWeight: 700, fontSize: 30, color: "black", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word", marginLeft: 200, marginTop: 100,marginRight: 200}}>
                <span style={{fontWeight: 700, fontSize: 30, color: "#1B7AFA", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word",}}>15 +</span> indicateurs de performance segmentables
                sur Date / Campagne
            </h2>
        )
    }

    return (
        <div style={{margin: "auto"}}>
            <Header title={"TABLEAUX DE BORD"} subTitle={"Emailing & Automation"} content1={content1()} content2={"Il existe de nombreux facteurs qui peuvent s’interposer entre vos emailings et vos lecteurs et avoir une incidence directe sur votre potentiel de chiffre d’affaires."}/>
            <InterractiveImage image={IMG_5}/>
            <InformationHeader content1={content1_info()} content2={null}/>
            <div style={{display: "flex", flexDirection:"column", marginRight: 150, marginLeft: 150, marginTop: 50,gap: 30, paddingBottom: 30 }}>


                <GridSquareCardCustom listCard={row_1}/>
                <GridSquareCardCustom listCard={row_2}/>
            </div>
        </div>
    )
}