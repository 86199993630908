import Header from "../../components/Analytics/Header";
import InterractiveImage from "../../components/InterractiveImage";
import InformationHeader from "../../components/InformationHeader";
import GridSquareCardCustom from "../../components/GridSquareCardCustom";
import React from "react";


import IMG_1 from "../../assets/2022/06/Groupe-1671.svg"; 
import IMG_2 from "../../assets/2022/06/Groupe-1670.svg";  
import IMG_3 from "../../assets/2022/06/Groupe-1669.svg";  
import IMG_4 from "../../assets/2022/06/Groupe-1668.svg";  
import IMG_5 from "../../assets/2022/06/Groupe-1667.svg";  
import IMG_6 from "../../assets/2022/06/GS_DEMO-1.png";  

const row_1 = [
    {
        content: "",
        title: "Visualiser l’état et les performances SEO du site internet",
        logo: IMG_1
    },
    {
        content: "",
        title: "Optimiser le contenu rédactionnel corrélé avec les requêtes",
        logo: IMG_2
    },

]

const row_2 = [    {
    content: "",
    title: "Piloter et orienter le trafic naturel",
    logo: IMG_3
},
    {
        content: "",
        title: "Démultiplier les interactions directes depuis les moteurs de recherches",
        logo: IMG_4
    }]

const row_3 = [ {
    content: "",
    title: "Monitorer les avis et notes des internautes",
    logo: IMG_5
}

]
// Youcef - ADD validation button for google api - Google Ads -
const row_4 = [
    {
        content: "",
        title: "Connectez vos sources d'acquisition rapidement via des connecteurs customiser \n",
        logo: ""
    }
]


export default function Referencement () {
    const content_1 = () => {
        return <>Les internautes sont submergés par des milliers de messages quotidien. Les marques doivent engager leurs audiences de manière plus significative pour se démarquer, rassembler plus de connaissances et <b>répondre au plus près des nouvelles attentes et usages de leurs utilisateurs.</b></>
    }

    const content1_info = () => {
        return (
            <h2 style={{fontWeight: 700, fontSize: 30, color: "black", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word", marginLeft: 200, marginTop: 100,marginRight: 200}}>
                <span style={{fontWeight: 700, fontSize: 30, color: "#1B7AFA", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word",}}>20 +</span>  indicateurs de performance segmentables par date
            </h2>
        )
    }

    const content2_info = () => {
        return (<p style={{textAlign: "center", fontSize: 18, padding: 10}}>
            <b>Swatix</b> permet de centraliser les performances SEO auprès des moteurs de recherche et des outils associés :


        </p>)
    }

    return (
        <div style={{margin: "auto"}}>
            <Header title={"TABLEAUX DE BORD"} subTitle={"Référencement naturel"} content1={"Le référencement naturel regroupe l’ensemble des pratiques et des optimisations ayant pour but de faire apparaître votre site internet dans les moteurs de recherche et de gagner en visibilité par un bon positionnement."} content2={"Les évolutions successives des algorithmes des moteurs de recherche procurent au domaine du référencement une dynamique constante."} content3={"Composante indispensable à un bon SEO local, les fiches “Entreprise” sur Google & Bing"}/>
            <InterractiveImage image={IMG_6}/>
            <InformationHeader content1={content1_info()} content2={content2_info()}/>
            <div style={{display: "flex", flexDirection:"column", marginRight: 150, marginLeft: 150, marginTop: 50,gap: 30, paddingBottom: 30 }}>


                <GridSquareCardCustom listCard={row_1}/>
                <GridSquareCardCustom listCard={row_2}/>
                <GridSquareCardCustom listCard={row_3}/>
                {/** Section pour valider les Api de connexion automatique */}
                <GridSquareCardCustom listCard={row_4} buttonConnexionValidator={true} typeValidation={2} />


            </div>
        </div>
    )
}