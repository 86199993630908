import React from 'react';
import logo from './logo.svg';
import './App.css';
import RouterManager from "./routes/RouterManager";
import LinkDecorator from './routes/LinkDecorator';
import Footer from "./components/Footer";
import {BrowserRouter} from "react-router-dom";

console.warn = function() {};
console.error = function() {};


function App() {
  return (
      <div>
      <BrowserRouter>

              <LinkDecorator />
              <RouterManager/>
                  {/*<link href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet"/>*/}

              <Footer/>
              
      </BrowserRouter>
      </div>

  );
}

export default App;
