import HeaderSolutionsEx from "../Solutions/HeaderSolutionsEx";
import SavoirPlus from "../SavoirPlus";


import IMG_6 from "../../assets/2022/06/Groupe-DP.png";

export default function PatrimoineDataHomepage ({}) {

    const content_1 = () => {
        return (
            <>
            <p>
                Swatix, une solution d’agrégation qui vous garantit la propriété de 100% de toutes les données de votre écosystème numérique (WEB, RS, NL, SEO, SEA, SMA, SMO…) .
            </p>
                <ul>
                    <li>
                        Créer et développer ses actifs Data
                    </li>
                    <li>
                        Concentrer sa connaissance client
                    </li>
                    <li>
                        Piloter sa connaissance client en toute autonomie
                    </li>
                    <li>
                        Approche globale de gouvernance des données client
                    </li>
                </ul>
                <SavoirPlus link={"/rgpd-by-design"}/>
            </>

        )
    }

    return (
        <HeaderSolutionsEx reverse={true} title={"PATRIMOINE DATA"} subtitle={"Une stratégie first party data"} content1={content_1()} image1={IMG_6}/>
    )
}