import Header from "../../components/Analytics/Header";
import AnalyticsDemo from "../../components/Analytics/AnalyticsDemo";
import React from "react";
import InterractiveImage from "../../components/InterractiveImage";
import InformationHeader from "../../components/InformationHeader";
import GridSquareCardCustom from "../../components/GridSquareCardCustom";

import IMG_1 from "../../assets/2022/06/Groupe-1674.svg"; 
import IMG_2 from "../../assets/2022/06/Groupe-1673.svg";  
import IMG_3 from "../../assets/2022/06/Groupe-1680.svg";  
import IMG_4 from "../../assets/2022/06/Groupe-1671.svg";  
import IMG_5 from "../../assets/2022/07/E-Commerce-demo.svg";  

const row_1 = [
    {
        content: "En un coup d’œil vous êtes en mesure de connaître les comportements d’achat de vos internautes et d’évaluer la performance de votre entonnoir de transaction. ",
        title: "Analyser les comportements d’achat",
        logo: IMG_1
    },
    {
        content: "Pour permettre l’augmentation de vos ventes en ligne, identifiez les produits les plus consultés, les plus achetés et optimisez votre stratégie de promotion.",
        title: "Optimiser la performance du catalogue de produits /services",
        logo: IMG_2
    },

]

const row_2 = [    {
    content: "Bénéficiez d’une vue sur tous vos leviers afin d’évaluer la performance de vos campagnes et leur impact sur le chiffre d’affaires.",
    title: "Évaluer la performance des campagnes e-Commerce",
    logo: IMG_3
},
    {
        content: "Exploitez pleinement vos données commerciales pour démultiplier les ventes, augmenter la valeur client et optimiser les ressources mobilisées.",
        title: "Suivre les ventes et pilotez la relation clients",
        logo: IMG_4
    }]


export default function Ecommerce () {


    return (
        <div style={{margin: "auto"}}>
            <Header title={"TABLEAUX DE BORD"} subTitle={"e-Commerce"} content1={"Pour démarquer votre boutique en ligne, vous avez besoin de données univoques."} content2={"pour évaluer la performance de votre stratégie de vente en ligne et ajuster vos tactiques"}/>
            <InterractiveImage image={IMG_5}/>

            <InformationHeader content1={null} content2={null}/>
            <div style={{display: "flex", flexDirection:"column", marginRight: 150, marginLeft: 150, marginTop: 50,gap: 30, paddingBottom: 30 }}>


            <GridSquareCardCustom listCard={row_1}/>
            <GridSquareCardCustom listCard={row_2}/>
            </div>
        </div>
    )
}