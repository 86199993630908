

import IMG_1 from "../../assets/2022/06/swa_bg1.png";

export default function BannerSolutions ({content}) {
    return (
        <div style={{height: 250, width: "100%", backgroundImage: "url(" + IMG_1 + ")", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <p style={{fontWeight: 700, color: "white", fontSize: 26, textAlign: "center", maxWidth: "60%", lineHeight: "1.5em"}}>
                {content}
            </p>
        </div>
    )
}