import React, {useState} from "react";
 

export default function InterractiveImage ({image}) {

    const height = 600

    const [isHovered, setIsHovered] = useState(false);

    const imageStyle = {
        transform: isHovered ? 'translateY(-50%)' : 'translateY(0)',
        opacity: isHovered ? 1: 0.4,
        transition: 'transform 5s ease-out, opacity 5s ease-out',
        contain: "content",
        maxWidth: 1000,

    };

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleLeave = () => {
        setIsHovered(false);
    };

    return (
        <div style={{height: 700}}>
            <div style={{height: 400, //backgroundImage: "url(http://152.228.166.218/wp-content/uploads/2022/06/Trace%CC%81-2154-Contour.png)"
            }}>
                {/*<div style={{position: "absolute", left: "50%", top: "80%", transform: "translate(-50%, -50%)"}}>*/}
                    <div style={{display: "flex", justifyContent: "center", paddingTop: 30}}>
                    <div style={{height: 600, overflow: "hidden", borderRadius: 20 }}       >
                        <img  src={image} style={imageStyle} onMouseEnter={handleHover} onMouseLeave={handleLeave}/>
                    </div>
                </div>
            </div>
        </div>
    )
}