import React from "react";


export default function InformationHeader ({content1, content2}) {
    return (
        <div>
            {content1 === null ? <h2 style={{fontWeight: 700, fontSize: 30, color: "black", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word", marginLeft: 200, marginTop: 100,marginRight: 200}}>
                <span style={{fontWeight: 700, fontSize: 30, color: "#1B7AFA", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word",}}>25 +</span> indicateurs de performance segmentables<br />
                sur Date / Source/ Support/ Campagne
            </h2> : content1}
            {content2 === null ? <p style={{textAlign: "center", fontSize: 18, padding: 10}}>
                <b>Swatix</b> permet d’optimiser votre performance sur l’ensemble du cycle des visites des sites
                internet :
            </p> : content2}
        </div>
    )
}