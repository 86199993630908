import Header from "../../components/Analytics/Header";
import InterractiveImage from "../../components/InterractiveImage";
import InformationHeader from "../../components/InformationHeader";
import GridSquareCardCustom from "../../components/GridSquareCardCustom";
import React from "react";

import IMG_1 from "../../assets/2022/06/Groupe-1686.svg"; 
import IMG_2 from "../../assets/2022/06/Groupe-1687.svg";  
import IMG_3 from "../../assets/2022/06/Groupe-1688.svg";  
import IMG_4 from "../../assets/2022/06/Groupe-1677.svg";  
import IMG_5 from "../../assets/2022/06/sm_demo-1.png";  
import IMG_6 from "../../assets/2022/06/GS_DEMO-1.png";  


const row_1 = [
    {
        content: "Évaluer la notoriété permet de savoir dans quelle mesure le public cible connaît la marque. C’est l’un des points les plus importants dans la prise de décision transactionnelle.",
        title: "Notoriété",
        logo: IMG_1
    },
    {
        content: "Validez la pertinence de la stratégie d’acquisition, de création de contenus qualitatifs pour développer les communautés d’intérêts et créer des bassins d’audiences au service des objectifs commerciaux.",
        title: "Acquisition",
        logo: IMG_2
    },

]

const row_2 = [    {
    content: "Le « bouche-à-oreille » en ligne alimente en continue votre funnel de conversion. Identifier les contenus et les réactions générées pour optimiser en continue la qualité des produits, services et formes de leur promotion.",
    title: "Engagement",
    logo: IMG_3
},
    {
        content: "Les profils sociaux de la marque affectent directement les ventes. Mesurez clairement les conversions consolidées de chacune des campagnes engagées auprès des audiences ciblées.",
        title: "Conversions",
        logo: IMG_4
    }]


export default function Reseaux () {

    const content_1 = () => {
        return <>Les internautes sont submergés par des milliers de messages quotidien.<br/> Les marques doivent engager leurs audiences de manière plus significative pour se démarquer, rassembler plus de connaissances et <b>répondre au plus près des nouvelles attentes et usages de leurs utilisateurs.</b></>
    }

    const content1_info = () => {
        return (
            <h2 style={{fontWeight: 700, fontSize: 30, color: "black", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word", marginLeft: 200, marginTop: 100,marginRight: 200}}>
                <span style={{fontWeight: 700, fontSize: 30, color: "#1B7AFA", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word",}}>20 +</span>  indicateurs de performance segmentables par date
            </h2>
        )
    }

    const content2_info = () => {
        return (<p style={{textAlign: "center", fontSize: 18, padding: 10}}>
            <b>Swatix</b> permet de centraliser les performances sur les réseaux sociaux :
        </p>)
    }

    return (
        <div style={{margin: "auto"}}>
            <Header title={"TABLEAUX DE BORD"} subTitle={"Réseaux Sociaux"} content1={content_1()} content2={""}/>
            <InterractiveImage image={IMG_5}/>
            <InformationHeader content1={content1_info()} content2={content2_info()}/>
            <div style={{display: "flex", flexDirection:"column", marginRight: 150, marginLeft: 150, marginTop: 50,gap: 30, paddingBottom: 30 }}>


                <GridSquareCardCustom listCard={row_1}/>
                <GridSquareCardCustom listCard={row_2}/>

            </div>
        </div>
    )
}