import {Link} from "react-router-dom";

import IMG_6 from "../../assets/2022/11/Ecran.svg"

export default function HomeHeader({ main1, main2, content1, content2 }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "80%",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: 50,
        margin: "auto",
        position: "relative",
      }}
    >
      <div>
        <h1>{main1}</h1>
        <h1>{main2}</h1>
      </div>
      <div>
        {content1 !== "" && (
          <h3
            style={{ fontWeight: "normal" }}
          >
            {content1}
          </h3>
        )}
        {content2 !== "" && (
          <h3 style={{ fontWeight: "bold" }}>
            {content2}
          </h3>
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: "80%",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "space-evenly",
          position: "relative",
          margin: "auto",
          paddingTop: "30px",
        }}
      >
          <Link to={"/pilotage-unifie/"} className="button"
                style={{
                    color: "#FFFFFF",
                    borderWidth: "2px",
                    borderColor: "#1B7AFA",
                    borderRadius: "50px",
                    fontSize: "18px",
                    backgroundColor: "#1B7AFA",
                    paddingRight: "1em !important",
                    paddingLeft: "1em !important",
                    display: "inline-block",
                    boxShadow: "0px 12px 18px -6px rgba(0,0,0,0.3)",
                    textDecoration: "none",
                    padding: "0.7em 1em",
                    lineHeight: "1.7em !important",
                }}>

          Performance &amp; Rentabilité
          </Link>

        <Link
          to="/rgpd-by-design/"
          className="button"
          style={{
            color: "#FFFFFF",
            borderWidth: "2px",
            borderColor: "#1B7AFA",
            borderRadius: "50px",
            fontSize: "18px",
            backgroundColor: "#1B7AFA",
            paddingRight: "1em !important",
            paddingLeft: "1em !important",
            display: "inline-block",
            boxShadow: "0px 12px 18px -6px rgba(0,0,0,0.3)",
            textDecoration: "none",
            padding: "0.7em 1em",
            lineHeight: "1.7em !important",
          }}
        >
          Conformité RGPD
        </Link>
        <Link
          to="/patrimoine-data/"
          className="button"
          style={{
            color: "#FFFFFF",
            borderWidth: "2px",
            borderColor: "#1B7AFA",
            borderRadius: "50px",
            fontSize: "18px",
            backgroundColor: "#1B7AFA",
            paddingRight: "1em !important",
            paddingLeft: "1em !important",
            display: "inline-block",
            boxShadow: "0px 12px 18px -6px rgba(0,0,0,0.3)",
            textDecoration: "none",
            padding: "0.7em 1em",
            lineHeight: "1.7em !important",
          }}
        >
          Souveraineté de la donnée
        </Link>
      </div>
      <div style={{
          display: "flex",
          width: "80%",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "space-evenly",
          position: "relative",
          margin: "auto",
          paddingTop: "30px",
        }}>
          <img src={IMG_6} alt="screen" style={{marginTop:"30px"}}></img>
        </div>
    </div>
  );
}
