import * as React from 'react';
import CustomInput from "../components/CustomInput";
import SweetAlertComponent from "../components/SweetAlert/SweetAlert"
import {useState} from "react";
import {rougeDayl} from "../App"; 

import contactForm from "../Services/EmailSender"


export default function Contact ({}) {

    const [societe, setSociete] = useState("")
    const [prenom, setPrenom] = useState("")
    const [nom, setNom] = useState("")
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [web, setWeb] = useState("")
    const [comment, setComment] = useState("")

    const [hoverSubmit, setSubmitHover] = useState(false)
    const [submitProcessing, setsubmitProcessing] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setdescription] = useState("")

    function submitContactForm(){ 
        setsubmitProcessing(true)
        
        let params = {
            title: "Demande de contact",
            emailSender: email,
            name: nom,
            firstname: prenom,
            society: societe,
            phone : tel,
            website: web,
            message: comment
        }

        if (!email || !nom || !prenom || !tel || !societe || !web || !comment){
            setTitle("Formulaire incomplet - Veuillez remplir tous les champs")
            setdescription("Nous vous remercions de bien vouloir remplir tous les champs obligatoires afin de pouvoir traiter votre demande efficacement")
            handleClickOpen()
            setsubmitProcessing(false)
            return
        }

        contactForm(params)
        .then(res => {
            console.log("res : ", res.data)
            setTitle("Message envoyé !")
            setdescription("Votre message a bien été envoyé avec succès. Notre équipe vous contactera dans les plus brefs délais pour répondre à votre demande.")
            handleClickOpen()
            setsubmitProcessing(false)
        })
        .catch(err => {
            console.log(err) 
            setTitle("Impossible d'envoyer le message !")
            setdescription("Nous sommes désolés, mais il semble que nous rencontrions actuellement des problèmes techniques qui nous empêchent d'envoyer votre message. Veuillez nous excuser pour ce désagrément.")
            handleClickOpen()
            setsubmitProcessing(false)
        })
    }



    // for sweet aleert pop up
    const [open, setOpen] = React.useState(false); 
    const handleClickOpen = () => {
        setOpen(true);
      }; 
      const handleClose = () => {
        setOpen(false);
      };
      

    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: 1000,
                backgroundColor: "#1B7AFA",
                padding: 50,
                margin: "auto",
                marginTop: 50,
                borderRadius: 15,
                paddingTop: 20
            }}>
                <h1 style={{color: "white", textAlign: "center", textTransform: "uppercase"}}>Contactez nous</h1>
                <CustomInput setText={setSociete} placeholder={"Société *"}/>

                <div style={{display: "flex", flexDirection: "row", flex: 1, gap: 20}}>
                    <CustomInput setText={setPrenom} placeholder={"Prénom *"}/>
                    <CustomInput setText={setNom} placeholder={"Nom *"}/>
                </div>
                <div style={{display: "flex", flexDirection: "row", flex: 1, gap: 20}}>
                    <CustomInput setText={setEmail} placeholder={"E-mail *"}/>
                    <CustomInput setText={setTel} placeholder={"Téléphone *"}/>
                </div>
                <CustomInput setText={setWeb} placeholder={"Site web *"}/>
                <textarea
                    onChange={(e) => {
                        setComment(e.target.value)
                    }} style={{
                    display: "flex",
                    flex: 1,
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 15,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "black",
                    margin: 10,
                    minHeight: 100
                }} placeholder={"Commentaire *"}/>


                {!submitProcessing ?  
                <div 
                id='submit-form'
                onClick={() => {
                        submitContactForm()
                }} style={{
                    padding: "10px 50px",
                    cursor:"pointer",
                    backgroundColor: hoverSubmit ? "black" : "white",
                    color: !hoverSubmit ? "black" : "white",
                    margin: "auto",
                    textAlign: "center",
                    borderRadius: 10,
                    transition: 'color 0.5s ease-out, background-color 0.5s ease-out',
                    marginTop: 30,
                }} onMouseEnter={() => setSubmitHover(!hoverSubmit)} onMouseLeave={() => setSubmitHover(!hoverSubmit)}>
                    Envoyer
                </div>
                :
                <div onClick={() => { 
                }} style={{
                    padding: "10px 50px", 
                    backgroundColor: hoverSubmit ? "black" : "white",
                    color: !hoverSubmit ? "black" : "white",
                    margin: "auto",
                    textAlign: "center",
                    borderRadius: 10,
                    transition: 'color 0.5s ease-out, background-color 0.5s ease-out',
                    marginTop: 30,
                }} onMouseEnter={() => setSubmitHover(!hoverSubmit)} onMouseLeave={() => setSubmitHover(!hoverSubmit)}>
                    Envoie en cours...
                </div>
            }


            </div>


            <SweetAlertComponent 
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                open={open}
                title={title}
                description={description}
            />
        </div>

    )
}