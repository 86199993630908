

export default function SmallSquareComponent ({content, image}) {
    return (
        <div style={{borderRadius: 20, gap: 20,flex: 1, boxShadow: "0px 4px 18px -6px rgba(0,0,0,0.3)",maxWidth: 180, backgroundColor: "white", display: "flex",  flexDirection: "column", alignItems: "center", textAlign: "center", padding: 5, paddingTop: 20, paddingBottom: 20,  }}>
            <img width="47" height="47" src={image}/>
            <p style={{lineHeight: "1.7em", maxWidth: "80%"}}>
                {content}


            </p>

        </div>
    )
}