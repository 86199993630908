import SmallInterractiveImage from "../SmallInteractiveImage";
import SavoirPlus from "../SavoirPlus";
import HeaderSolutionsEx from "../Solutions/HeaderSolutionsEx";

import IMG_1 from "../../assets/2022/06/roas_demo-1.png";
import IMG_2 from "../../assets/2022/06/sm_demo-1.png";
import IMG_3 from "../../assets/2022/06/nl_demo.png";


const content_1 = () => {
    return (
        <>
            <h3 style={{ fontWeight: 800, color: "#1B7AFA", fontSize: 20 }}>
                RETOUR SUR INVESTISSEMENTS
            </h3>
            <h3 style={{ fontWeight: 800, fontSize: 20 }}>Pilotage Unifié</h3>
            <p>
                Nous appliquons une procédure éprouvée et standardisée de Data Cleansing
                pour que vous n’ayez plus qu’à vous concentrer sur l’analyse et le
                pilotage des performances de votre écosystème marketing numérique.
            </p>
            <SavoirPlus link="/pilotage-unifie/"/>
        </>
    );
};
const content_2 = () => {
    return (
        <>
            <h3 style={{ fontWeight: 800, color: "#1B7AFA", fontSize: 20 ,textTransform: "uppercase"}}>
            ECONOMIE DE LA DONNEE
            </h3>
            <h3 style={{ fontWeight: 800, fontSize: 20 }}>
            Exhaustivité et Propriété
            </h3>
            <p>
            Demeurez propriétaire des données issues de votre
            écosystème WEB.
            Par ailleurs, nous nous garantissons 100% de la
            précision des données collectées, refusant
            notamment la stratégie d’échantillonnage des
            données
            </p>
            <SavoirPlus link="/patrimoine-data/"/>
        </>
    );
};
const content_3 = () => {
    return (
        <>
            <h3 style={{ fontWeight: 800, color: "#1B7AFA", fontSize: 20 ,textTransform: "uppercase"}}>
                règlementation
            </h3>
            <h3 style={{ fontWeight: 800, fontSize: 20 }}>RGPD by Design</h3>
            <p>
                Un nouvel outil de collecte de données analytics web qui respecte la vie
                privée de vos utilisateurs : approuvé et recommandé par la CNIL
            </p>
            <SavoirPlus link="/patrimoine-data/"/>
        </>
    );
};


export default function Section3() {

    const interactiveImage =(image)=>{
        return (
            <div style={{maxWidth: "40%"}}>
                <SmallInterractiveImage height={300} image={image}/>
            </div>
        )
    }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        width: "70%",
        margin: "auto",
        position: "relative",
        marginTop:"5%"

      }}
    >

        <HeaderSolutionsEx
            title={""}
            subtitle={""}
            content1={content_1()}
            image1=""
            interactive={true}
            interactive_content={interactiveImage(IMG_1)}
        />
        <HeaderSolutionsEx
            title={""}
            subtitle={""}
            content1={content_2()}
            image1={""}
            reverse={true}
            interactive={true}
            interactive_content={interactiveImage(IMG_2)}
        />
        <HeaderSolutionsEx
            title={""}
            subtitle={""}
            content1={content_3()}
            image1={""}
            interactive={true}
            interactive_content={interactiveImage(IMG_3)}
        /> 
    </div>
  );
}
