

export default function SquareCardCustom ({title, content, logo, listMode = false, heightLogo , widthLogo}) {
    return (
        <div style={{boxShadow: "0px 6px 18px -6px rgba(0,0,0,0.3)", display: "block", overflow: "hidden", flex: 1, borderRadius: 25, marginRight: 20, backgroundColor: "white",marginLeft: 20, paddingBottom: 20,paddingRight:20, paddingLeft: 20}}>
            <div style={{}}>
      <span style={{}}>

            <img decoding="async" width={widthLogo} height={heightLogo}
                 src={logo} alt="" title="Groupe 1674"
                 style={{marginTop: 10}}/>

      </span>
            </div>
            <div>
                <div style={{marginRight: 40, paddingRight: 20, textAlign: "left", marginBottom: 10}}>
                    <h3 style={{fontWeight: listMode? 800: 600, fontSize: 20, color: listMode ? "#1B7AFA" : "black", marginLeft: listMode? 20 : 0}}>{title}</h3>
                </div>
            </div>
            <div>
                <div style={{lineHeight: 2, textAlign: "left"}}>
                    <p>{content}</p>
                </div>
            </div>
        </div>
    )
}