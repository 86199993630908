export default function Header ({title, subTitle, content1, content2, content3 = ""}) {
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", paddingTop: 50}}>

            <h2 style={{fontWeight: 700, color: "#1B7AFA", textTransform: "uppercase", fontSize: 18 }}>
                {title}
            </h2>
            <h1 style={{fontWeight: 600}} >
                {subTitle}
            </h1>
            <h3 style={{fontWeight: "normal", textAlign: "center", paddingRight: 300, paddingLeft: 300, marginBottom:0}}>
                {content1}
            </h3>
            {content2 !== "" && <h3 style={{marginRight: 200, marginLeft: 200, fontWeight: "normal", marginBottom:0}}>
                {content2}

            </h3>}
            {content3 !== "" && <h3 style={{marginRight: 200, marginLeft: 200, fontWeight: "normal", marginBottom:0}}>
                {content3}

            </h3>}

        </div>
    )
}