import React from "react";
import Header from "../../components/Analytics/Header";
import AnalyticsDemo from "../../components/Analytics/AnalyticsDemo";
import SquareCardCustom from "../../components/SquareCardCustom";


export default function Analytics() {

    const test = () => {
        return <><b> La donnée est un actif stratégique qui permet d’optimiser son activité :</b> <br /> acquisition,
            rétention, ciblage…</>
    }

    return (
        <div style={{margin: "auto"}}>
            <Header title={"TABLEAUX DE BORD"} content1={test()}
                    content2={"Plus elle est enrichie, analysée et structurée pour des usages spécifiques, plus sa valorisation est importante "}
                    content3={"et plus elle permet d’acquérir ou de conserver un avantage stratégique."}
                    subTitle={"Web Analytics"}/>
            <AnalyticsDemo/>

        </div>
    )
}