import React, {useState} from "react";


export default function SmallInterractiveImage ({image, height = 600}) {


    const [isHovered, setIsHovered] = useState(false);

    const imageStyle = {
        transform: isHovered ? 'translateY(-50%)' : 'translateY(0)',
        transition: 'transform 4s ease-out',
        contain: "content",
        maxWidth: "100%",
        margin: "auto",
        display: "block"
    };

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleLeave = () => {
        setIsHovered(false);
    };

    return (
            <div style={{height: 400}}>
                {/*<div style={{position: "absolute", left: "50%", top: "80%", transform: "translate(-50%, -50%)"}}>*/}
                    <div style={{display: "flex", justifyContent: "center", paddingTop: 30}}>
                    <div style={{height: height, overflow: "hidden", borderRadius: 20, borderWidth: 2, borderColor: "black", borderStyle: "solid" }}       >
                        <img  src={image} style={imageStyle} onMouseEnter={handleHover} onMouseLeave={handleLeave}/>
                    </div>
                </div>
            </div>

    )
}