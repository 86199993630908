import React from 'react';
import Navbar from './Navbar';

const Layout = (props) => {
  return (
    <>
      <Navbar />
      <main style={{paddingTop:"3%"}}>{props.children}</main>
    </>
  );
};

export default Layout;