export default function MentionLegal() {
  return <div style={{display:"flex",flexDirection:"column",width:"80%",margin:"auto",position:"relative"}}>
    <h1>MENTIONS LEGALES</h1>
    <h2 style={{color:"#1B7AFA"}}>Editeur :</h2>
    <p>Swatix : SAS, au capital de 50 000 €, dont le siège est 171 Ter Avenue Charles de Gaulle 92200 Neuilly sur Seine, immatriculée au R.C.S de Nanterre sous le numéro 810 168 138, représentée  Mr. Olivier Lefièvre en qualité de Président.</p>
    <h2 style={{color:"#1B7AFA"}}>Hébergement :</h2>
    <p>OVH SAS au capital de 10 069 020 €  – RCS Roubaix – Tourcoing 424 761 419 00045 – Code APE 2620Z – N° TVA : FR 22 424 761 419 – Siège social : 2 rue Kellermann – 59100 Roubaix – France.</p>
    <h2 style={{color:"#1B7AFA"}}>Droits de reproduction :</h2>
    <p>Les informations présentées sur ce site sont publiques. La reproduction des pages de ce site est autorisée à la condition d’y mentionner la source et de créer un lien sur la ou les pages de référence. Elles ne peuvent être utilisées à des fins commerciales ou publicitaires.</p>
  </div>;
}
