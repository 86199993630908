import {Link} from "react-router-dom";

export default function FooterMenu ({listItems}) {
    return (
        <div style={{display: "flex", flexDirection: "column", color: "white"}}>
            <h3 style={{marginTop: 0, fontSize:15,fontWeight:"1000"}}>
                {listItems.title}
            </h3>
            {
                listItems && listItems.items.map((el) => {
                    return (
                        <Link to={el.link} key={el.item} className={"legalMention"} style={{margin: 0, lineHeight: "2em", fontSize: 13, fontWeight: 500, color: 'white', textDecoration: 'none'}}>
                            <h3 style={{margin: 0}}>{el.item}</h3>
                        </Link>
                    )
                })
            }
        </div>
    )
}
