import Header from "../../components/Analytics/Header";
import InterractiveImage from "../../components/InterractiveImage";
import InformationHeader from "../../components/InformationHeader";
import GridSquareCardCustom from "../../components/GridSquareCardCustom";
import React from "react";

import IMG_1 from "../../assets/2022/06/Groupe-1679.svg"; 
import IMG_2 from "../../assets/2022/06/Groupe-1680.svg";  
import IMG_3 from "../../assets/2022/06/Groupe-1681.svg";  
import IMG_4 from "../../assets/2022/06/Groupe-1682.svg";  
import IMG_5 from "../../assets/2022/06/Groupe-1683.svg";  
import IMG_6 from "../../assets/2022/09/gads.svg";  

import IMG_8 from "../../assets/2022/06/Groupe-1674.svg";

const row_1 = [
    {
        content: "",
        title: "Suivre et classer la ventilation des investissements par résultats",
        logo: IMG_1
    },
    {
        content: "",
        title: "Identifier les plateformes qui génèrent des performances",
        logo: IMG_2
    },

]

const row_2 = [    {
    content: "",
    title: "Monitorer et attribuer les conversions réels générées",
    logo: IMG_3
},
    {
        content: "",
        title: "Monitorer et attribuer les conversions réels générées",
        logo: IMG_4
    }]

const row_3 = [
    {
        content: "",
        title: "Piloter votre ROI via des KPI média et métier\n",
        logo: IMG_5
    }
]

// Youcef - ADD validation button for google api - Google Ads -
const row_4 = [
    {
        content: "",
        title: "Connectez vos sources d'acquisition rapidement via des connecteurs customiser \n",
        logo: ""
    }
]

export default function Publicite () {

    const content1_info = () => {
        return (
            <h2 style={{fontWeight: 700, fontSize: 30, color: "black", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word", marginLeft: 200, marginTop: 100,marginRight: 200}}>
                <span style={{fontWeight: 700, fontSize: 30, color: "#1B7AFA", lineHeight: "1.2em", textAlign: "center", wordWrap: "break-word",}}>15 +</span> indicateurs de performance segmentables <br />par Date, Source, Support & Campagne
            </h2>
        )
    }

    const content2_info = () => {
        return (<p style={{textAlign: "center", fontSize: 18, padding: 10}}>
            <b>Swatix</b> permet de centraliser les performances sur les plateformes publicitaires :
        </p>)
    }

    return (
        <div style={{margin: "auto"}}>
            <Header title={"TABLEAUX DE BORD"} subTitle={"Publicité"} content1={"Le fonctionnement du marché de la publicité numérique, particulièrement complexe"} content2={" peut être résumé à travers trois grandes caractéristiques :"} content3={"l’économie de l’attention, l’omniprésence des données, l’automatisation des processus d’achat/vente"} />
            <InterractiveImage image={IMG_6}/>
            <InformationHeader content1={content1_info()} content2={content2_info()}/>
            <div style={{display: "flex", flexDirection:"column", marginRight: 150, marginLeft: 150, marginTop: 50,gap: 30, paddingBottom: 30 }}>


                <GridSquareCardCustom listCard={row_1}/>
                <GridSquareCardCustom listCard={row_2}/>
                <GridSquareCardCustom listCard={row_3}/>
                {/** Section pour valider les Api de connexion automatique */}
                <GridSquareCardCustom listCard={row_4} buttonConnexionValidator={true} typeValidation={1} />
            </div>
        </div>
    )
}