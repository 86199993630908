import SquareCardCustom from "./SquareCardCustom";
import SquareCardCustomWithValidatorButton from "./SquareCardCustomWithValidationButton";


export default function GridSquareCardCustom ({listCard, listMode = false, heightLogo = 106, widthLogo = 97,  buttonConnexionValidator=false, typeValidation=0}) {
    return (
        <div style={{display: "flex", flexDirection: "row", gap: 20, marginLeft: 150, marginRight: 150}}>
            {
                listCard.map((el, key) => { 
                    if (!buttonConnexionValidator)
                        return (
                            <SquareCardCustom key={key} title={el.title} content={el.content} logo={el.logo} listMode={listMode}  />
                        )
                    else
                        return (
                            <SquareCardCustomWithValidatorButton key={key} title={el.title} content={el.content} logo={el.logo} listMode={listMode} buttonConnexionValidator={buttonConnexionValidator} typeValidation={typeValidation} />
                        )
                })
            }
        </div>
    )
}