import React from "react";
import Pricing from "./Pricing/Pricing" 
import PricingDemo from "../components/Pricing/PricingDemo";
import HeaderPricing from "../components/Pricing/header";
import Header from "../components/Analytics/Header"; 

export default function PricingPage() {


  const test = () => {
    return <span style={{color:"#1b7afa", fontSize:25}} >
      Swatix ANALYTICS pour suivre et piloter votre écosystème Web <br /> <span style={{color:"#1b7afa", fontSize:20}}>RGPD by Design</span> 
          </span>
}

  return (
    <div>  
            <Header title={""} content1={""}
                    content2={""}
                    content3={""}
                    subTitle={test()}/>
        <Pricing />
        <PricingDemo/>
    </div>
  );
}
