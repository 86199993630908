import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  Button,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Navbar = () => {
  const [open, setOpen] = useState([false, false, false]);
  const [dropdownClicked, setDropdownClicked] = useState(false);

  const anchorRefs = [useRef(null), useRef(null), useRef(null)];
  const location = useLocation();
  const history = useNavigate();

  // Get mtm parameters from query string
  const queryParams = new URLSearchParams(location.search);
  const mtmSource = queryParams.get("mtm_source");
  const mtmMedium = queryParams.get("mtm_medium");
  const mtmCampaign = queryParams.get("mtm_campaign");

  const handleToggle = (index) => {
    setOpen(open.map((value, i) => (i === index ? !value : false)));
    setDropdownClicked(!dropdownClicked);
  };

  const handleClose = (event, index) => {
    if (
      anchorRefs[index].current &&
      anchorRefs[index].current.contains(event.target)
    ) {
      return;
    }
    setOpen(open.map((value, i) => (i === index ? false : value)));
    setDropdownClicked(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleButtonClick = () => {
    if (dropdownClicked) {
      setDropdownClicked(false);
    }
  };

  // Add mtm parameters to the target URL when clicking a menu item
  const handleMenuItemClick = (event, index, path) => {
    event.preventDefault();

    // Create an object with the non-null UTM parameters
    const utmParams = {};
    if (mtmSource  && mtmSource !== "null" ) utmParams.mtm_source = mtmSource;
    if (mtmMedium  && mtmMedium !== "null" ) utmParams.mtm_medium = mtmMedium;
    if (mtmCampaign  && mtmCampaign !== "null" ) utmParams.mtm_campaign = mtmCampaign;
    
    // Generate the query string with the UTM parameters
    const queryString = new URLSearchParams(utmParams).toString();

    // Append the query string to the target URL
    const targetURL = queryString  ? `${path}?${queryString}` : path; 

    history(targetURL);
    handleClose(event, index);
  };

  const dropDownMenu = (index, items) => (
    <Popper
      open={open[index]}
      anchorEl={anchorRefs[index].current}
      role={undefined}
      transition
      disablePortal
      style={{ zIndex: 1300 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener
              onClickAway={(event) => handleClose(event, index)}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#1B7AFA",
                    height: "4px",
                    width: "100%",
                  }}
                />
                <MenuList
                  autoFocusItem={open[index]}
                  id="menu-list-grow"
                  onMouseLeave={() => {
                    handleToggle(index);
                    handleButtonClick();
                  }}
                  onMouseEnter={() => {
                    handleToggle(index);
                    handleButtonClick();
                  }}
                >
                  {items.map((item, i) => (
                    <MenuItem
                      key={i}
                      component={Link}
                      to={item.path}
                      onClick={(event) =>
                        handleMenuItemClick(event, index, item.path)
                      }
                      sx={{
                        textDecoration: "none",
                        color: "inherit",
                        padding: "6%",
                        fontSize: "14px",
                        fontFamily: "'Montserrat', sans-serif",
                        width: "200px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const dropdownItems = [
    [
      { label: "ANALYTICS", path: "/analytics" },
      { label: "E-COMMERCE", path: "/e-commerce" },
      { label: "PUBLICITÉS - SEA & SMA", path: "/publicites-sea-sma" },
      { label: "RÉSEAUX SOCIAUX", path: "/reseaux-sociaux" },
      { label: "RÉFÉRENCEMENT NATUREL", path: "/referencement-naturel" },
      { label: "EMAILING & AUTOMATION", path: "/emailing-automation" },
    ],
    [
      { label: "PILOTAGE UNIFIÉ", path: "/pilotage-unifie" },
      { label: "PATRIMOINE DATA", path: "/patrimoine-data" },
      { label: "RGPD BY DESIGN", path: "/rgpd-by-design" },
      { label: "EXHAUSTIVITÉ DE LA DONNÉE", path: "/exhaustivite-de-la-donnee" },
    ],
    [],
  ];
  const dropdownLabels = ["TABLEAUX DE BORD", "SOLUTIONS", "OFFRES"];

  return (
    <AppBar sx={{ backgroundColor: "white" }}>
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            fontFamily: "'Montserrat', sans-serif",
            marginLeft: "6%",
          }}
        >
          <Box
            component={Link}
            to="/"
            style={{ textDecoration: "none" }}
            textTransform="uppercase"
            color="#1B7AFA"
          >
            Swatix
          </Box>
        </Typography>

        {/* The dropdown menus */}
        {dropdownLabels.map((label, index) => (
          <React.Fragment key={index}>
            {label === "OFFRES" ? null : (
              <Button
                onMouseEnter={() => {
                  handleToggle(index);
                  handleButtonClick();
                }}
                onMouseLeave={() => {
                  handleToggle(index);
                  handleButtonClick();
                }}
                color="inherit"
                ref={anchorRefs[index]}
                aria-controls={open[index] ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  handleToggle(index);
                  handleButtonClick();
                }}
                sx={{
                  marginRight:
                    index === dropdownLabels.length - 1 ? "5%" : "2%",
                  color: "black",
                  fontFamily: "'Montserrat', sans-serif",
                }}
              >
                {label}
                <ArrowDropDownIcon />
              </Button>
            )}
            {dropDownMenu(index, dropdownItems[index], anchorRefs[index])}
          </React.Fragment>
        ))}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            component={Link}
            to="/pricing"
            sx={{
              textDecoration: "none",
              color: "black",
              marginRight: "2vw",
              fontSize: "17px",
              fontFamily: "'Montserrat', sans-serif",
              cursor: "pointer",
              padding: "7px 7px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            Offres d'abonnement
          </Typography>
        </Box>

        {/* The box containing the two buttons */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            component={Link}
            to="/demo"
            sx={{
              marginRight: "1rem",
              borderRadius: "50px",
              textTransform: "uppercase",
              fontFamily: "'Montserrat', sans-serif",
              backgroundColor: "#1B7AFA",
            }}
          >
            Démo
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "50px",
              fontFamily: "'Montserrat', sans-serif",
              backgroundColor: "black",
              marginRight: "30px",
            }}
          >
            +33 1 46 37 22 42
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
