import SquareCardCustom from "../SquareCardCustom";
import React, { useState } from "react";
import GridSquareCardCustom from "../GridSquareCardCustom";
import InterractiveImage from "../InterractiveImage";
import InformationHeader from "./InformationHeader";


import IMG_1 from "../../assets/2022/06/Groupe-1679.svg";
import IMG_2 from "../../assets/2022/06/Groupe-1682.svg";
import IMG_3 from "../../assets/2022/06/Groupe-1680.svg";
import IMG_4 from "../../assets/2022/06/Groupe-1671.svg";
import IMG_5 from "../../assets/2022/06/Groupe-1668.svg";
import IMG_6 from "../../assets/2022/06/Groupe-1677.svg";
import IMG_7 from "../../assets/2022/06/Groupe-1683.svg";

const row_1 = [
  {
    content:  "",
    title: "Suivre et classer la ventilation des investissements par résultats",
    logo: IMG_1,
  },
  {
    content: "",
    title: "Mesurer le ROAS consolidé des campagnes engagées",
    logo: IMG_2,
  },
  {
    content: "",
    title: "Identifier les sources de trafics qui génèrent des performances",
    logo: IMG_3,
  },
];

const row_2 = [
  {
    content: "",
    title: "Visualiser l’état et les performances SEO du site internet",
    logo: IMG_4,
  },
  {
    content: "",
    title: "Démultiplier les interactions depuis les plateformes d’audiences",
    logo: IMG_5,
  },
  {
    content: "",
    title: "Mesurer et attribuer les conversions",
    logo: IMG_6,
  },
];

const row_3 = [
  {
    content: "",
    title: "Piloter votre ROI via des KPI média et métier",
    logo: IMG_7,
  }, 
];

export default function AnalyticsDemo({}) {
  return ( 
    <div style={{
      background: "rgba(27,122,250,0.15) ",
      paddingTop: 2
    }} > 

      <div>
        <InformationHeader content1={null} content2={null} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            marginTop: 50,
            margin: "auto",
            position: "relative",
            gap: 30,
            paddingBottom: 30,
          }}
        >
          <GridSquareCardCustom listCard={row_1} />
          <GridSquareCardCustom listCard={row_2} />
          <GridSquareCardCustom listCard={row_3} />
        </div>
      </div>
    </div>
  );
}
