import GridSquareCardCustom from "../GridSquareCardCustom";
import InterractiveImage from "../InterractiveImage";
import SavoirPlus from "../SavoirPlus";


import IMG_1 from "../../assets/2022/07/analytics.svg";
import IMG_2 from "../../assets/2022/07/social_media.svg";
import IMG_3 from "../../assets/2022/07/ads2.svg";
import IMG_4 from "../../assets/2022/07/seo.svg";
import IMG_5 from "../../assets/2022/07/newsletter.svg";
import IMG_6 from "../../assets/2022/07/ecommerce.svg";
import IMG_7 from "../../assets/2022/09/gads.svg";

const row_1 = [
  {
    content:
        <><p>
            Des capacités de visualisation ultra précises pour une analyse indépendante et exhaustive de l’activité réelle sur le site internet...
        </p>
            <SavoirPlus link={"/analytics"}/>
        </>,
    title: "Analytics",
    logo: IMG_1,
  },
  {
    content:
        <><p>
            Une vision complète de la présence sociale, des audiences et des activités. Une vision centralisée et croisée avec les performances web générées.
        </p>
            <SavoirPlus link={"/reseaux-sociaux/"}/>
        </>
      ,
    title: "Réseaux sociaux",
    logo: IMG_2,
  },

  {
    content:
        <><p>
            Supervision des investissements sur toutes les plateformes utilisées, ROAS consolidé pour un pilotage des performances par le “coût par action”.
        </p>
            <SavoirPlus link={"/publicites-sea-sma/"}/>
        </>,
    title: "Publicité – SEA & SMA",
    logo: IMG_3,
  },
];






const row_2 = [
  {
    content:
        <><p>L’état de l’art du référencement naturel auprès des moteurs de recherche et les performances des fiches “Entreprise”.</p>
            <SavoirPlus link={"/referencement-naturel/"}/>
        </>,
    title: "Référencement",
    logo: IMG_4,
  },
  {
    content:
      <><p>Suivi des performances “routage” des emails avec des statistiques détaillées croisées avec les performances réelles générées sur le site internet.     </p>
          <SavoirPlus link={"/emailing-automation/"}/>
      </>,
    title: "Emailing & Newsletters",
    logo: IMG_5,
  },
  {
    content:
      <><p>Exploiter le véritable potentiel des données commerciales: maximisez les résultats sur tout le cycle d’achat : attirer, séduir et fidéliser les clients.
      </p>
          <SavoirPlus link={"/e-commerce/"}/>
      </>,
    title: "E-commerce",
    logo: IMG_6,
  },
];




export default function Section1({}) {
  return (
    <div>
      <InterractiveImage
        image={IMG_7}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            margin: "auto",
            position: "relative",
            marginTop: 50,
            gap: 30,
            paddingBottom: 30,
          }}
        >
          <GridSquareCardCustom listCard={row_1} heightLogo={37} widthLogo={37}/>
          <GridSquareCardCustom listCard={row_2} heightLogo={37} widthLogo={37}/>
        </div>
      </div>
    </div>
  );
}
