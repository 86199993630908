import React, {useEffect} from "react";
import FooterMenu from "./FooterMenu";
import {Link} from "react-router-dom";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import {Message} from "@mui/icons-material";
import { useLocation } from 'react-router-dom';


import IMG_1 from "../assets/2022/06/Planifier.svg";
import IMG_2 from "../assets/2023/01/Logo3_blanc_X.png";
import IMG_3 from "../assets/2022/06/linkedin.png";
import IMG_4 from "../assets/2022/06/twitter.png";



const listItems = [{
    title: "TABLEAUX DE BORD", items: [{
        item: "Analytics", link: "/analytics/",
    }, {
        item: "e-Commerce", link: "/e-commerce/",
    }, {
        item: "SMA & SEA", link: "/publicites-sea-sma/",
    }, {
        item: "Réseaux Sociaux", link: "/reseaux-sociaux/",
    }, {
        item: "Référencement", link: "/referencement-naturel/",
    }, {
        item: "Emailing & Newsletter", link: "/emailing-automation/",
    },],
}, {
    title: "SOLUTIONS", items: [{
        item: "Pilotage unifié", link: "/pilotage-unifie/",
    }, {
        item: "Data Patrimoine", link: "/patrimoine-data/",
    }, {
        item: "R.G.P.D by design", link: "/rgpd-by-design/",
    }, {
        item: "Exhaustivité des données", link: "/exhaustivite-de-la-donnee/",
    },  {
        item: "Connecteurs", link: "",
    },],
}, {
    title: "À PROPOS", items: [{
        item: "Contact", link: "/demo",
    }, ],
},];

const buttonStyle = {
    fontSize: "20px",
    fontWeight: 500,
    padding: "0.6em 1em",
    lineHeight: "1.7em!important",
    backgroundColor: "#101221",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    border: "2px solid",
    transitionDuration: ".2s",
    transitionProperty: "all!important",
    color: "white",
    borderColor: "white",
    borderRadius: 25,
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    gap: 10,
    paddingRight: "1em",
    paddingLeft: "1em",
    width: 200,
    marginLeft: 50,
    marginTop: 10,
    textDecoration: "none",
};



const smallFooter = ["/MentionLegal", "/demo", "/demo/", "/protection-des-donnees-personnelles"]

export default function Footer({}) {


    const phoneNumber = '+33 1 46 37 22 42';

    const location = useLocation();
    const isDemoPage = !location.pathname.includes('/demo') ; 


    return (<div>

        {!smallFooter.includes(window.location.pathname) ?

            <div
                style={{
                height: isDemoPage   ? 800 : 100,
                display: "flex",
                flex: 1,
                backgroundColor: "white",
                alignItems: "center",
                flexDirection: "column",
            }}
        >

            {isDemoPage &&
            <div
                style={{
                    width: "60%",
                    backgroundColor: "#1B7AFA",
                    height: 200,
                    display: "flex",
                    marginTop: 150,
                    borderRadius: 20,
                }}
            >
                <div style={{display: "flex", flex: 1.5, flexDirection: "column"}}>
                    <h2
                        style={{
                            fontWeight: 700,
                            color: "white",
                            fontSize: 28,
                            textAlign: "left",
                            marginTop: 20,
                            marginLeft: 20,
                            marginBottom: 5,
                        }}
                    >
                        Pilotez votre stratégie webmarketing avec un outil d'aide à la
                        décision clair et exhaustif
                    </h2>

                    <Link style={buttonStyle} to="/demo">
                        Planifier une démo
                    </Link>
                </div>
                <div
                    style={{
                        display: "flex", flex: 1, justifyContent: "center", overflow: "visible",
                    }}
                >
                    <img
                        style={{objectFit: "none", overflow: "visible"}}
                        alt={"image2"}
                        src={IMG_1}
                    />
                </div>
            </div>
            } 
            <div
                style={{
                    display: "flex", flexDirection: "row", gap: 80, marginTop: 250,
                }}
            > 
            <div style={buttonStyle}  className={"buttonTelephone"}>
              <a href={`tel:${phoneNumber}`} style={{color:"white", textDecoration:"None"}} >
                <PhoneIcon style={{
                    position:"relative",
                    top:"4px",
                    marginRight: 5
                }} />
                {phoneNumber}
              </a>
            </div>
                <Link style={buttonStyle} to="/demo" className={"buttonTelephone"}>
                    <EmailIcon/>
                    Contactez-nous
                </Link>
            </div> 
        </div>
            : <div style={{ padding: 50}}></div>

        }


        <div style={{padding: 55, backgroundColor: "#101221"}}>
            <div
                style={{display: "flex", flexDirection: "row", marginBottom: 50}}
            >
                <div
                    style={{
                        flex: 1, display: "flex", flexDirection: "column", paddingRight: 30,
                    }}
                >
                    <div style={{width: "65%", display: "flex"}}>
                        <Link to="/">
                            <img
                                style={{overflow: "auto", width: '146%', height : "260px"}}
                                src={IMG_2}
                            />
                        </Link>
                    </div>
                    <h3 style={{marginTop: 35, color: "white", fontWeight: "normal"}}>
                        Solution souveraine au service exclusif de l’optimisation de vos
                        investissements Web Marketing.
                    </h3>
                    <div
                        style={{
                            display: "flex", gap: 20, flexDirection: "row", marginTop: 25,
                        }}
                    >
                        <img
                            style={{height: 40, width: "auto"}}
                            src={IMG_3}
                            alt="linkedin"
                        />
                        <Link to="https://twitter.com/Swatix_eu" target="_blank">
                            <img
                                style={{height: 40, width: "auto"}}
                                src={IMG_4}
                                alt="twitter"
                            />
                        </Link>
                    </div>
                </div>
                <div
                    style={{
                        flex: 1.5, marginLeft: "12%", display: "flex", flexDirection: "row", gap: 100,
                    }}
                >
                    {listItems && listItems.map((el, index) => {
                        return <FooterMenu key={index} listItems={listItems[index]}/>;
                    })}
                </div>
            </div>

            <div
                style={{
                    backgroundColor: "#1B7AFA",
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 50,
                    fontSize: 18,
                    justifyContent: "space-between",
                    padding: 30,
                    paddingRight: 60,
                    paddingLeft: 60,
                }}
            >
                <span style={{color: "white"}}>© 2023 Swatix</span>
                <div
                    style={{
                        color: "white", display: "flex", flexDirection: "row", gap: 10, fontSize: 18,
                    }}
                >


                    <Link to={"/MentionLegal"} style={{textDecoration: "none"}}> 
                        <span className={"legalMention"} style={{color: "white"}}>
                            Mentions légales
                        </span>
                    </Link>

                    <span style={{color: "white"}}>|</span>

                    <Link to={"/protection-des-donnees-personnelles"} style={{textDecoration: "none"}}> 
                        <span className={"legalMention"} style={{color: "white"}}>
                        Données Personnelles
                        </span>
                    </Link>
 

 
                </div>
            </div>
        </div>
    </div>);
}
